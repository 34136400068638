import { CustomImage, IconWarningAlt } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const FormContainer = styled.div`
  display: grid;
  grid-row-gap: 32px;
  padding: 30px;
  max-width: 972px;
  margin: 0 auto;
`

export const StyledCustomImage = styled(CustomImage)`
  @media (max-width: ${tokens.lgMin}) {
    padding-top: 8px;
  }
  @media (max-width: ${tokens.smMin}) {
    padding-top: 13px;
  }
`

export const StyledTitleIcon = styled(IconWarningAlt)`
  color: ${tokens.colorOrange6} !important;
  margin-top: 2px;
`
