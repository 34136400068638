import { Card, IconWarning, Table } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const CloudFormTitle = styled.h6`
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 23px;
  @media (max-width: ${tokens.lgMin}) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  @media (max-width: ${tokens.smMin}) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  border-bottom: 1px solid ${tokens.colorGray2};
`

export const CapabilitiesLoader = styled.div`
  padding: 32px 10px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CloudFormTable = styled(Table)`
  padding: 12px 24px 24px 24px;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  width: 100% !important;
  min-width: 200px !important;
  button {
    top: calc(34px / 2) !important;
  }
  .css-zq7vkh {
    display: flex;
    align-items: center;
  }
`

export const CloudFormSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px 6px 24px;
  & button {
    margin-left: 15px;
    margin-top: 10px;
  }

  @media (max-width: ${tokens.smMin}) {
    display: flex;
    flex-direction: column;

    button {
      margin-left: 0px;
      margin-bottom: 10px;
      width: 100%;
    }
  }
`

export const CloudSelectLabel = styled.p`
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  min-width: 200px !important;
  & img {
    margin-right: 10px;
  }
  & span {
    margin-top: 2px;
  }
`

export const MainContainer = styled(Card)`
  display: grid;
  grid-template-rows: auto auto;
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  float: right;
  svg {
    cursor: pointer !important;
    margin-right: 12px !important;
  }
`

export const ProviderInfoContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ProviderInfoTypeEmailContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  padding-left: 11px;
`

export const ProviderText = styled.div`
  color: ${tokens.colorGray10};
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  padding-top: 2px;
`

export const ProviderFolderIconContainer = styled.div`
  display: flex;
  padding-right: 6px;
`

export const ProviderSiteIconContainer = styled.div`
  display: flex;
  padding-right: 6px;
`

export const ErrorContainer = styled(Card)`
  display: flex;
  padding: 40px 10px 48px;
`
export const ProviderEmailText = styled.div`
  margin-top: 2px;
  margin-left: 2px;
  color: ${tokens.colorGray10};
`

export const ReauthorizeText = styled.div`
  cursor: pointer;
  color: ${tokens.colorHpBlue6} !important;
`

export const StyledIconWarning = styled(IconWarning)`
  margin-right: 11px;
`

export const AuthenticationFailedContainer = styled.div`
  color: ${tokens.colorGray10};
  margin-top: -14px;
  margin-left: 20px;
`

export const InvalidFolder = styled.div`
  width: auto;
  margin-left: 5px;
  color: #adadad !important;
  cursor: default;
  display: -webkit-box;

  svg {
    margin-left: 4px;
    margin-bottom: -7px;
  }
`
