import {
  IconChevronRight,
  IconConnectivity,
  ProgressIndicator,
} from "@veneer/core"
import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { DeviceProps } from "../../../mfe/types/device"
import { i18n } from "../../assets/I18n"
import {
  ConnectContainer,
  ConnectTitle,
  Link,
  LinkContainer,
  PrinterDataContainer,
  PrinterDetailsContainer,
  PrinterImageContainer,
  StatusLoader,
  Subtitle,
  Title,
} from "./styles"

type PrinterWidgetDetailsProps = {
  printer: DeviceProps
  printerStatus: string
  isLoadingStatus: boolean
}

const PrinterWidgetDetails = (props: PrinterWidgetDetailsProps) => {
  const history = useHistory()
  const { base } = useParams()

  return (
    <PrinterDetailsContainer id="printer-details-container">
      <PrinterImageContainer>
        <img src={props.printer.images.slice(-1)[0].url} alt="printer" />
      </PrinterImageContainer>
      <PrinterDataContainer id="printer-data-container">
        <Title className="subtitle-large">
          {props.printer.identity.friendlyName
            ? props.printer.identity.friendlyName
            : props.printer.identity.makeAndModel.name}
        </Title>
        <Subtitle className="body">
          {props.printer.identity.makeAndModel.name}
        </Subtitle>
        {props.isLoadingStatus ? (
          <StatusLoader>
            <ProgressIndicator />
          </StatusLoader>
        ) : (
          <>
            {props.printerStatus === "offline" ? (
              <ConnectContainer id="connect-container">
                <IconConnectivity size={19} />
                <ConnectTitle className="caption">
                  {i18n.assetsProvider.getText(
                    "pages.ScanDestinations.printerDetails.printerNotConnected"
                  )}
                </ConnectTitle>
              </ConnectContainer>
            ) : (
              <ConnectContainer id="connect-container">
                <IconConnectivity filled color="colorHpBlue5" size={19} />
                <ConnectTitle>
                  {i18n.assetsProvider.getText(
                    "pages.ScanDestinations.printerDetails.printerConnected"
                  )}
                </ConnectTitle>
              </ConnectContainer>
            )}
          </>
        )}
        <LinkContainer id="link-container">
          <Link
            className="label"
            onClick={() => {
              history.push(`${base}/${props.printer.deviceId}`)
            }}
          >
            {i18n.assetsProvider.getText(
              "pages.ScanDestinations.printerDetails.printerDetailsText"
            )}
          </Link>
          <IconChevronRight color="colorHpBlue6" />
        </LinkContainer>
      </PrinterDataContainer>
    </PrinterDetailsContainer>
  )
}

export default PrinterWidgetDetails
