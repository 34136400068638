import { JarvisAuthProvider } from "@jarvis/web-http"
import {
  IconFolder,
  IconFolderOpen,
  ProgressIndicator,
  TreeView,
  TreeViewId,
} from "@veneer/core"
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { i18n } from "../../assets/I18n"
import { CapabilityItem } from "../../data/schemas/connector"
import { FolderItem } from "../../data/schemas/folders"
import { flattenTree } from "../../helpers/treeViewMapping"
import useTreeViewNodes from "../../hooks/helpers/useTreeViewNodes"
import ErrorWidget from "../ErrorWidget"
import {
  CustomButton,
  FooterContainer,
  LoaderContainer,
  StyledModal,
} from "./styles"

type FolderTreeModalProps = {
  stack: number
  authProvider: JarvisAuthProvider
  currentConnector: CapabilityItem
  isVisible: boolean
  editSelectedFolder: string
  onNodeSelect?: { (id: string): void }
  onClose: { (): void }
  onConfirm: { (folderItem: FolderItem): void }
}

type Node = {
  id: string
  label: string
  icon: JSX.Element
  nodes?: Node[]
}

const FolderTreeModal = (props: FolderTreeModalProps) => {
  const [expandedNodes, setExpandedNodes] = useState<TreeViewId[]>([])
  const [selectedNodes, setSelectedNodes] = useState<TreeViewId[]>([])

  const {
    isLoadingFolders,
    folderTree,
    errorOnFetchFolder,
    onFolderListingOpen,
    onNodeExpand,
    cleanFolderTree,
  } = useTreeViewNodes({ stack: props.stack, authProvider: props.authProvider })
  /*
    This useEffect will guarantee that only when the currentConnectorId property changes
    the expandedNodes state will be reseted.
  */

  useEffect(() => {
    if (props.currentConnector) {
      const { connectorId, connectorName } = props.currentConnector

      if (props.isVisible && connectorId && connectorName) {
        onFolderListingOpen(connectorId, connectorName)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentConnector, props.isVisible])

  const getNodeIcon = useCallback(
    (folder: FolderItem) => {
      if (expandedNodes.includes(folder.id)) {
        return folder.loadingFolderData ? (
          <ProgressIndicator />
        ) : (
          <IconFolderOpen />
        )
      }

      return <IconFolder />
    },
    [expandedNodes]
  )

  const getNodeChildren = useCallback(
    (nodeData: FolderItem[]): Node[] => {
      if (!nodeData) {
        return []
      }

      return nodeData.map((folder) => {
        let statusNode = null

        if (!folder.loaded) {
          statusNode = {
            icon: null,
            id: "",
            label: "",
          }
        }

        if (folder.loaded && !folder.data) {
          setSelectedNodes(new Array(folder.id))
        }

        return {
          icon: getNodeIcon(folder),
          id: folder.id,
          label: folder.name,
          nodes: statusNode
            ? [statusNode]
            : getNodeChildren(folder?.data || []),
        }
      })
    },
    [getNodeIcon]
  )

  const fetchFolderHandler = useCallback(() => {
    // eslint-disable-next-line no-console
    const { connectorId, connectorName } = props.currentConnector
    onFolderListingOpen(connectorId, connectorName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentConnector])

  const onNodeExpandHandler = (
    _: SyntheticEvent,
    newExpandedNodes: TreeViewId[]
  ) => {
    onNodeExpand(newExpandedNodes[newExpandedNodes.length - 1]?.toString())
    setExpandedNodes(newExpandedNodes)
  }

  const onNodeSelect = (_: SyntheticEvent, id: TreeViewId | TreeViewId[]) => {
    if (id && !Array.isArray(id)) {
      setSelectedNodes(new Array(id))
    }
  }

  const nodes: Node[] = useMemo(
    () =>
      folderTree.map((node) => {
        return {
          id: node.id,
          label: node.name,
          icon: getNodeIcon(node),
          nodes: getNodeChildren(node.data || []),
        }
      }),
    [folderTree, getNodeIcon, getNodeChildren]
  )

  const handleSaveButton = () => {
    const mappedTree = flattenTree(folderTree)
    props.onConfirm(
      mappedTree.find((folder) => folder.id === selectedNodes[0]?.toString()) ??
        mappedTree.find(
          (folderTree) => folderTree.id === props.editSelectedFolder
        )
    )
    setSelectedNodes([])
    cleanFolderTree()
  }

  const handleCancelButton = () => {
    setSelectedNodes([])
    setExpandedNodes([])
    cleanFolderTree()
    props.onClose()
  }

  return (
    <StyledModal
      id="modal-select-folder-test"
      title={i18n.assetsProvider.getText(
        "pages.ShortcutCreation.cloudSection.folderTitle"
      )}
      show={props.isVisible}
      onClose={() => {}}
      footer={
        <FooterContainer>
          <CustomButton
            appearance="primary"
            onClick={handleSaveButton}
            disabled={!selectedNodes.length}
          >
            {i18n.assetsProvider.getText("common.save")}
          </CustomButton>
          <CustomButton
            disabled={isLoadingFolders}
            appearance="secondary"
            onClick={handleCancelButton}
          >
            {i18n.assetsProvider.getText("common.cancel")}{" "}
          </CustomButton>
        </FooterContainer>
      }
    >
      {isLoadingFolders ? (
        <LoaderContainer>
          <ProgressIndicator aria-label="folders loader" />
        </LoaderContainer>
      ) : errorOnFetchFolder ? (
        <ErrorWidget onRetry={() => fetchFolderHandler()} />
      ) : (
        <TreeView
          nodes={nodes}
          expandedNodes={expandedNodes}
          selectedNodes={
            props.editSelectedFolder && !selectedNodes.length
              ? Array(props.editSelectedFolder)
              : selectedNodes
          }
          onToggle={onNodeExpandHandler}
          onChange={onNodeSelect}
        />
      )}
    </StyledModal>
  )
}

export default FolderTreeModal
