import React from "react"
import { i18n } from "../../assets/I18n"
import {
  EmailContentContainer,
  EmailTitle,
  MainContainer,
  MessageTextArea,
  MultipleRecipientsDescription,
  ReceiverTextArea,
  SubjectTextArea,
} from "./styles"

type EmailFormSectionProps = {
  emailFieldLabel: string
  emailValue?: string
  hasEmailErrors: boolean
  emailHelperText?: string
  onChangeEmail: { (value: string): void }
  onBlurEmail?: { (): void }
  subjectFieldLabel: string
  subjectValue?: string
  onChangeSubject: { (value: string): void }
  bodyFieldLabel: string
  bodyValue?: string
  onChangeBody: { (value: string): void }
}

const EmailFormSection = (props: EmailFormSectionProps) => {
  const CardContent = () => (
    <>
      <EmailTitle>
        {i18n.assetsProvider.getText(
          "pages.ShortcutCreation.emailSection.title"
        )}
      </EmailTitle>
      <MultipleRecipientsDescription className="body">
        {i18n.assetsProvider.getText(
          "pages.ShortcutCreation.emailSection.multipleRecipientsDescription"
        )}
      </MultipleRecipientsDescription>
      <ReceiverTextArea
        id="email-receiver-input"
        label={props.emailFieldLabel}
        onChange={props.onChangeEmail}
        onBlur={props.onBlurEmail}
        value={props.emailValue}
        error={props.hasEmailErrors}
        helperText={props.emailHelperText}
        helperTextVisibility="visible"
        height={"98px"}
        required
      />
      <EmailContentContainer>
        <SubjectTextArea
          id="email-subject-input"
          label={props.subjectFieldLabel}
          onChange={props.onChangeSubject}
          value={props.subjectValue}
          height={"72px"}
          required
        />
        <MessageTextArea
          id="email-body-input"
          label={props.bodyFieldLabel}
          onChange={props.onChangeBody}
          height={"98px"}
          value={props.bodyValue}
        />
      </EmailContentContainer>
    </>
  )

  return (
    <MainContainer id="email-form-main-container" content={CardContent()} />
  )
}

export default EmailFormSection
