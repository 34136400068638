import { Card } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100% !important;
  @media screen and (max-width: ${tokens.mdMin}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const StyledCard = styled(Card)`
  padding: 24px;
  background-color: white !important;
  margin: 24px 30px 24px 30px;
`

export const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 64px;

  @media screen and (max-width: ${tokens.mdMin}) {
    align-self: center;
    padding: 24px 0px;
    width: 100%;
    position: static;
  }
`

export const VerticalLine = styled.div`
  position: static;
  width: 148px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  transform: rotate(-90deg);

  @media screen and (max-width: ${tokens.mdMin}) {
    width: 100%;
    border: 1px solid rgba(33, 33, 33, 0.1);
    transform: rotate(-180deg);
  }
`
