import tokens from "@veneer/tokens"
import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const IconContainer = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: rgba(33, 33, 33, 0.1);
  align-items: center;
  justify-content: center;
`

export const Text = styled.div`
  color: ${tokens.colorGray7};
  padding-top: 10px;
  padding-bottom: 8px;
  text-align: justify;
`
