import { IconChevronRight, Modal } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const StyledModal = styled(Modal)`
  div.vn-modal--dialog {
    @media (max-width: ${tokens.smMin}) {
      display: flex;
      justify-content: center;
    }
  }
  div.vn-modal--content {
    width: 900px;
    padding: 40px 24px 24px 24px;
    h2 {
      padding-bottom: 24px !important;
    }

    @media (max-width: ${tokens.mdMax}) {
      width: 716px;
    }

    @media (max-width: ${tokens.smMax}) {
      width: 528px;
    }

    @media (max-width: ${tokens.smMin}) {
      width: 90%;
      height: max-content;
    }
  }

  div.vn-modal--body {
    overflow: hidden !important;
  }

  button {
    justify-content: flex-end !important;
  }
  z-index: 1400 !important;
  /* Works on Firefox */
  div {
    scrollbar-width: thin;
    scrollbar-color: rgba(115, 115, 115, 0.4) rgba(250, 250, 250, 1);
  }
  /* Works on Chrome, Edge, and Safari */
  div::-webkit-scrollbar {
    width: 10px;
  }
  div::-webkit-scrollbar-track {
    background: rgba(250, 250, 250, 1);
  }
  div::-webkit-scrollbar-thumb {
    background-color: rgba(115, 115, 115, 0.4);
    border-radius: 20px;
    border: 3px solid rgba(250, 250, 250, 1);
  }
`

export const CustomDescription = styled.p`
  margin-bottom: 16px;
`

export const ParentBox = styled.div`
  &::after {
    display: flex;
    content: "";
    border-bottom: 1px solid rgba(33, 33, 33, 0.1);
    margin-top: 10px;
  }
`

export const AuthNewAccFrame = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 17px;
  height: 290px;
  overflow-y: auto;

  @media (max-width: ${tokens.mdMax}) {
    overflow: hidden;
    height: auto;
    gap: 16px;
    padding: 10px;
  }

  @media (max-width: ${tokens.smMin}) {
    grid-template-columns: 100%;
    padding: 5px;
  }
`

export const AuthAccFrame = styled.div`
  display: flex;
  border-radius: 14px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  height: 300px;
  flex-direction: column;
  overflow-y: auto;
  margin: 18px 0px;
`

export const ProviderItemContainer = styled.div`
  display: grid;
  grid-template-columns: 15% 70% 15%;
  cursor: pointer;
  width: 96%;
  height: 96px;
  margin-top: 8px;
  margin-bottom: 10px;
  margin-left: 8px;
  border-radius: 16px;
  border: 1px solid rgba(33, 33, 33, 0.1);
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
  @media (max-width: ${tokens.mdMax}) {
    margin: 0;
  }

  @media (max-width: ${tokens.mdMin}) {
    grid-template-columns: 1fr 2fr 1fr;
  }

  @media (max-width: ${tokens.smMin}) {
    display: flex;
    width: 100%;
  }

  @media (max-width: 270px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const ProviderItemImage = styled.div`
  align-self: center;
  display: flex;
  margin-left: 14px;

  @media (max-width: ${tokens.smMin}) {
    img {
      width: 36px !important;
    }
  }
`

export const ProviderItemHeader = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  align-items: center;
  margin-left: 8px;

  @media (max-width: ${tokens.mdMin}) {
    grid-template-columns: 80% 20%;
    gap: 8px;

    div {
      margin-left: 8px !important;
    }
  }

  @media (max-width: ${tokens.smMin}) {
    display: flex;
    width: 100%;
  }

  @media (max-width: 270px) {
    width: auto;
  }
`

export const ProviderItemName = styled.p`
  color: ${tokens.colorGray10};
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;

  @media (max-width: ${tokens.mdMin}) {
    width: fit-content;
    white-space: break-spaces !important;
    font-size: 18px;
  }

  @media (max-width: ${tokens.smMin}) {
    white-space: nowrap !important;
  }

  @media (max-width: ${tokens.smMin}) {
    margin: 0;
    margin-right: 5px;
  }

  @media (max-width: 390px) {
    white-space: break-spaces !important;
  }

  @media (max-width: 300px) {
    font-size: 12px !important;
  }

  @media (max-width: 270px) {
    text-align: center;
  }
`

export const ConnectorItemName = styled.p`
  color: ${tokens.colorGray10};
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ConnectorItemType = styled.p``

export const ConnectorItemImage = styled.div`
  align-self: center;
  display: flex;
  margin-left: 14px;
`

export const ConnectorItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
  white-space: nowrap;
  width: auto;
  text-overflow: ellipsis;
  margin-right: 10px;
`

export const ConnectorItemContainer = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  min-height: 64px;
  max-height: 64px;
  display: flex;
  align-items: center;
`

export const CustomTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const StyledIconChevronRight = styled(IconChevronRight)`
  justify-content: flex-end;
  margin: 0 16px;
`

export const ChevronContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  @media (max-width: ${tokens.smMin}) {
    width: auto;
  }
`

export const ItemDivider = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${tokens.colorGray10} 10%;
  &::after {
    content: "";
    border-bottom: 1px solid rgba(33, 33, 33, 0.1);
    width: 100%;
    background-color: rgba(33, 33, 33, 0.1);
  }
`

export const Divider = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${tokens.colorGray10} 10%;
  &::after {
    content: "";
    border-bottom: 1px solid rgba(33, 33, 33, 0.1);
    margin-top: 17px;
    width: 100%;
    background-color: rgba(33, 33, 33, 0.1);
  }
`

export const CustomErrorText = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const ErrorWidgetContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  button {
    justify-content: center !important;
  }
`

export const TopContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 18px 0px;
`

export const AlignProTooltip = styled.div`
  margin-top: 5px;
`
