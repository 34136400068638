import { useEffect, useState } from "react"
import { i18n } from "../../assets/I18n"

// eslint-disable-next-line no-control-regex
const emailRegex = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/

const useEmailValidation = () => {
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")

  const emailEntries = email
    .split(",")
    .map((string) => string.trim())
    .filter((value) => value)

  const isValidEmail = (entry: string) => emailRegex.test(entry)

  const invalidEmails = emailEntries.filter((entry) => !isValidEmail(entry))

  useEffect(() => {
    if (/,\S/.test(email)) {
      setEmail(emailEntries.join(", "))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  useEffect(() => {
    if (emailEntries.length > 20) {
      setEmailError(
        i18n.assetsProvider.getText(
          "pages.ShortcutCreation.validators.invalidQuantityEmailMessage"
        )
      )
    } else if (invalidEmails.length) {
      setEmailError(
        i18n.assetsProvider.getText(
          "pages.ShortcutCreation.validators.invalidEmailMessage",
          { emails: invalidEmails.join(", ") }
        )
      )
    } else {
      setEmailError("")
    }
  }, [emailEntries, invalidEmails])

  return {
    email,
    setEmail,
    emailError,
  }
}

export default useEmailValidation
