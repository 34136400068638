import { JarvisAuthProvider } from "@jarvis/web-http"
import { Tabs, useToast } from "@veneer/core"
import Button from "@veneer/core/dist/scripts/button"
import IconReload from "@veneer/core/dist/scripts/icons/icon_reload"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { DeviceProps } from "../../../mfe/types/device"
import { i18n } from "../../assets/I18n"
import Images from "../../assets/images"
import ErrorWidget from "../../components/ErrorWidget"
import Loader from "../../components/Loader"
import PrinterWidget from "../../components/PrinterWidget"
import ScanToDestination from "../../components/ScanToDestination"
import { CapabilityItem, Site } from "../../data/schemas/connector"
import {
  FolderId,
  FolderNameRequest,
  FolderNameResponse,
} from "../../data/schemas/folders"
import { ShortcutItem, SiteIdItem } from "../../data/schemas/shortcut"
import { getDateAndTime } from "../../helpers/date"
import useQuery from "../../helpers/useQuery"
import useDeviceApi from "../../hooks/api/useDeviceApi"
import useDeviceShadow from "../../hooks/api/useDeviceShadow"
import useTenantApi from "../../hooks/api/useTenantApi"
import {
  ErrorWidgetContainer,
  LastUpdatedContainer,
  ScanDestinationsContainer,
  ScanDestinationsTabsContainer,
} from "./styles"

type ScanDestinationsTabsProps = {
  stack: number
  authProvider: JarvisAuthProvider
  printer: DeviceProps
  sendUiEvent: { (uiDataObj: object): void }
}

type ScanDestinationTabsContentProps = ScanDestinationsTabsProps & {
  deviceUuid: string
  selectedTabByQuery: string
  deviceShortcuts: ShortcutItem[]
  shortcutDeleted: boolean
  isLoading: boolean
  setShortcutDeleted: { (value: boolean): void }
  disabled: boolean
  showErrorWidget: boolean
  onRetry: { (): void }
  printer: DeviceProps
  capabilities: CapabilityItem[]
  folderNameItems: FolderNameResponse
  siteItem: Site[]
}

const Content = ({
  stack,
  authProvider,
  deviceUuid,
  selectedTabByQuery,
  deviceShortcuts,
  isLoading,
  shortcutDeleted,
  setShortcutDeleted,
  disabled,
  showErrorWidget,
  onRetry,
  sendUiEvent,
  capabilities,
  printer,
  folderNameItems,
  siteItem,
}: ScanDestinationTabsContentProps) => {
  const resolveSelectedTabByQuery = (tab: string) =>
    ({
      email: 0,
      cloud: 1,
    }[tab] || 0)

  const [selectedTabId, setSelectedTabId] = useState<string | number>(
    resolveSelectedTabByQuery(selectedTabByQuery)
  )

  const ErrorWidgetWrapper = () => (
    <ErrorWidgetContainer id="error-widget-wrapper-container">
      {isLoading ? <Loader /> : <ErrorWidget onRetry={onRetry} />}
    </ErrorWidgetContainer>
  )

  const ScanToEmail = () => (
    <ScanToDestination
      tab={selectedTabId}
      imgSrc={Images.emailAction}
      title={i18n.assetsProvider.getText(
        "pages.ScanDestinations.scanToEmail.title"
      )}
      subtitle={i18n.assetsProvider.getText(
        "pages.ScanDestinations.scanToEmail.subtitle"
      )}
      destinationType="email"
      stack={stack}
      authProvider={authProvider}
      deviceUuid={deviceUuid}
      isLoading={isLoading}
      tableData={deviceShortcuts?.filter((shortcut: ShortcutItem) =>
        shortcut.category.includes("email") &&
        shortcut.smartTask.smartTaskConfig.email.tos.length !== 0
          ? true
          : false
      )}
      onDeleteShortcut={setShortcutDeleted}
      shortcutDeletedState={shortcutDeleted}
      disabled={disabled}
      sendUiEvent={sendUiEvent}
      totalDeviceShortcuts={deviceShortcuts?.length}
    />
  )

  const ScanToCloud = () => (
    <ScanToDestination
      tab={selectedTabId}
      imgSrc={Images.uploadAction}
      title={i18n.assetsProvider.getText(
        "pages.ScanDestinations.scanAndSave.title"
      )}
      subtitle={i18n.assetsProvider.getText(
        "pages.ScanDestinations.scanAndSave.subtitle"
      )}
      destinationType="cloud"
      stack={stack}
      authProvider={authProvider}
      deviceUuid={deviceUuid}
      isLoading={isLoading}
      tableData={deviceShortcuts?.filter((shortcut: ShortcutItem) =>
        shortcut.category.includes("save")
      )}
      onDeleteShortcut={setShortcutDeleted}
      shortcutDeletedState={shortcutDeleted}
      disabled={disabled}
      sendUiEvent={sendUiEvent}
      capabilities={capabilities}
      cloudId={printer.deviceId}
      folderNameItems={folderNameItems}
      siteItem={siteItem}
      totalDeviceShortcuts={deviceShortcuts?.length}
    />
  )

  const tabs = [
    {
      id: 0,
      label: i18n.assetsProvider.getText(
        "pages.ScanDestinations.scanToEmail.title",
        {
          shortcutCount: deviceShortcuts
            ?.filter((shortcut: ShortcutItem) => {
              if (
                shortcut.category.includes("email") &&
                shortcut.smartTask.smartTaskConfig.email.tos.length !== 0
              )
                return true
              else return false
            })
            .length.toString(),
        }
      ),
      content: showErrorWidget ? <ErrorWidgetWrapper /> : <ScanToEmail />,
    },
    {
      id: 1,
      label: i18n.assetsProvider.getText(
        "pages.ScanDestinations.scanAndSave.title",
        {
          shortcutCount: deviceShortcuts
            ?.filter((shortcut: ShortcutItem) =>
              shortcut.category.includes("save")
            )
            .length.toString(),
        }
      ),
      content: showErrorWidget ? <ErrorWidgetWrapper /> : <ScanToCloud />,
    },
  ]

  return (
    <Tabs
      controlId="extended"
      mode="extended"
      onChangeTab={setSelectedTabId}
      selectedTabId={selectedTabId}
      tabs={tabs}
    />
  )
}

const ScanDestinationsTabs = (props: ScanDestinationsTabsProps) => {
  const query = useQuery()
  const { base } = useParams()
  const history = useHistory()
  const cloudId = props.printer.deviceId
  const deviceUuid = props.printer.identity.deviceUuid

  const { addToast } = useToast()

  const [shortcutDeleted, setShortcutDeleted] = useState(false)
  const [showQuickSetModal, setShowQuickSetModal] = useState(false)
  const [showErrorWidget, setShowErrorWidget] = useState(false)
  const [dateAndTime, setDateAndTime] = useState<string>()
  const {
    isLoading,
    capabilities,
    folderNameItems,
    siteItem,
    getCapabilities,
    getDeviceShortcuts,
    getFoldersNames,
    getAdminSitesSharepoint,
    deviceShortcuts,
    getDeviceSupportStatus,
    // createDeviceSession,
  } = useTenantApi(props.stack, props.authProvider)

  const {
    enableScanDestinationStatus,
    getScanDestinationStatus,
  } = useDeviceShadow(props.stack, props.authProvider)

  const { getDeviceStatus, statusData, isLoadingStatus } = useDeviceApi(
    props.stack,
    props.authProvider
  )

  const getScanDestinationStatusHandler = async (cloudId: string) => {
    try {
      setDateAndTime(getDateAndTime(new Date()))
      await getScanDestinationStatus(cloudId)
      setShowErrorWidget(false)
    } catch (error) {
      setShowErrorWidget(true)
    }
  }

  const refreshPageContent = () => {
    const currentLocale = i18n.getCurrentLocale()
    let currentPath = window.location.pathname.replace(currentLocale, "")
    history.replace(`${currentPath} `)
    setTimeout(() => {
      history.replace(currentPath)
    }, 0)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { isSupported } = await getDeviceSupportStatus(deviceUuid)
        if (!isSupported || sessionStorage.getItem("entitlement") == null)
          history.push(`${base}`)
      } catch (error) {
        history.push(`${base}`)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // This timeout is necessary because the way one of the toast provider functions
    // work, when this useEffect is triggered for the first time, there is an
    // undefined variable. And it breaks the app with 'TypeError: currentToasts
    // is undefined'. This will be fixed in a future Veneer version.
    setTimeout(() => {
      if (sessionStorage.getItem("creationSuccess")) {
        addToast({
          id: `smb-scan-destinations-creation-success`,
          type: "positive",
          text: i18n.assetsProvider.getText(
            "pages.ScanDestinations.toasts.saveChangesSuccess"
          ),
        })
        sessionStorage.removeItem("creationSuccess")
      }
    }, 3000)
  }, [addToast])

  useEffect(() => {
    getScanDestinationStatusHandler(cloudId)
    // FIXME: Ignore to avoid infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudId])

  useEffect(() => {
    getDeviceShortcuts(deviceUuid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceUuid])

  useEffect(() => {
    if (shortcutDeleted) {
      setShortcutDeleted(false)
      getDeviceShortcuts(deviceUuid)
    }
    // FIXME: Ignore to avoid infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortcutDeleted])

  useEffect(() => {
    getDeviceStatus(cloudId)
    // FIXME: Ignore to avoid infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudId])

  useEffect(() => {
    getCapabilities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let folderIdList: FolderId[] = []
    let folderIdObj = {} as FolderNameRequest

    if (deviceShortcuts.length) {
      for (const shortcut of deviceShortcuts) {
        if (shortcut.smartTask.smartTaskConfig.repositories) {
          for (const repository of shortcut.smartTask.smartTaskConfig
            .repositories) {
            if (repository.folderList) {
              for (const folderItem of repository.folderList) {
                folderIdList.push({ id: folderItem.folderId })
              }
            }
          }
        }
      }
    } else {
      return
    }
    folderIdObj["folderIds"] = folderIdList

    if (Object.keys(folderIdObj.folderIds).length) {
      getFoldersNames(folderIdObj)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceShortcuts])

  useEffect(() => {
    const fetchSharepoint = async () => {
      let siteIdList: SiteIdItem[] = []
      let objList: Site[] = []

      if (deviceShortcuts.length) {
        for (const shortcut of deviceShortcuts) {
          if (shortcut.smartTask.smartTaskConfig.repositories) {
            for (const repository of shortcut.smartTask.smartTaskConfig
              .repositories) {
              if (repository.sites) {
                const response: Site[] = await getAdminSitesSharepoint(
                  repository.connectorId
                )
                if (response.length) {
                  for (const item of response) {
                    objList.push(item)
                  }
                  return objList
                }
                for (const site of objList) {
                  siteIdList.push({ siteID: site.id })
                }
              }
            }
          }
        }
      } else {
        return
      }
    }

    fetchSharepoint()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceShortcuts])

  useEffect(() => {
    if (showQuickSetModal && enableScanDestinationStatus?.isEnabled) {
      setShowQuickSetModal(false)
    }
  }, [enableScanDestinationStatus, showQuickSetModal])

  if (isLoading && !showQuickSetModal && !showErrorWidget) {
    return <Loader />
  }

  return (
    <ScanDestinationsContainer id="scan-destination-page-container">
      <LastUpdatedContainer id="last-updated-container" className="caption">
        <Button
          aria-label="reload-button"
          leadingIcon={<IconReload />}
          appearance="ghost"
          small={true}
          onClick={refreshPageContent}
        ></Button>
        <span aria-label="reload-button-caption">
          {i18n.assetsProvider.getText(
            "pages.ScanDestinations.lastUpdated.lastUpdatedMessage"
          )}{" "}
          {dateAndTime}
        </span>
      </LastUpdatedContainer>
      <PrinterWidget
        printer={props.printer}
        printerStatus={
          statusData?.state?.reported?.cdmData?.connectivityState
            ?.connectionState || "offline"
        }
        isLoadingStatus={isLoadingStatus}
        emailCount={deviceShortcuts
          ?.filter((shortcut: ShortcutItem) => {
            if (
              shortcut.category.includes("email") &&
              shortcut.smartTask.smartTaskConfig.email.tos.length !== 0
            )
              return true
            else return false
          })
          ?.length.toString()}
        saveCount={deviceShortcuts
          ?.filter((shortcut: ShortcutItem) =>
            shortcut.category.includes("save")
          )
          .length.toString()}
      />
      <ScanDestinationsTabsContainer id="scan-destinations-tabs-container">
        <Content
          {...props}
          selectedTabByQuery={query.get("tab")}
          deviceUuid={deviceUuid}
          deviceShortcuts={deviceShortcuts}
          isLoading={isLoading}
          setShortcutDeleted={setShortcutDeleted}
          shortcutDeleted={shortcutDeleted}
          disabled={!enableScanDestinationStatus?.isEnabled}
          showErrorWidget={showErrorWidget}
          onRetry={() => {
            getScanDestinationStatusHandler(cloudId)
          }}
          capabilities={capabilities}
          folderNameItems={folderNameItems}
          siteItem={siteItem}
        />
      </ScanDestinationsTabsContainer>
    </ScanDestinationsContainer>
  )
}

export default ScanDestinationsTabs
