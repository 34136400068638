import React from "react"
import { ToggleContainer } from "./styles"

type ToggleScanDestinationsProps = {
  onChange: (organizationName: string) => void
  disabled: boolean
  on: boolean
}

const ToggleScanDestinations: React.FC<ToggleScanDestinationsProps> = ({
  onChange,
  disabled,
  on,
}) => {
  return (
    <>
      <ToggleContainer
        id="toggle-scan-destinations"
        data-testid="toggle-scan-destinations"
        onChange={onChange}
        disabled={disabled}
        on={on}
      />
    </>
  )
}

export default ToggleScanDestinations
