import React from "react"
import { DeviceProps } from "../../../mfe/types/device"
import PrinterWidgetDetails from "../PrinterWidgetDetails"
import PrinterWidgetShortcutInfo from "../PrinterWidgetShortcutInfo"
import {
  CardContentContainer,
  LineContainer,
  StyledCard,
  VerticalLine,
} from "./styles"

type PrinterWidgetProps = {
  printer: DeviceProps
  saveCount: string
  emailCount: string
  printerStatus: string
  isLoadingStatus: boolean
}

const PrinterWidget = (props: PrinterWidgetProps) => {
  const CardContent = () => (
    <CardContentContainer id="card-content-container">
      <PrinterWidgetDetails
        printer={props.printer}
        printerStatus={props.printerStatus}
        isLoadingStatus={props.isLoadingStatus}
      />
      <LineContainer>
        <VerticalLine />
      </LineContainer>
      <PrinterWidgetShortcutInfo
        emailCount={props.emailCount}
        saveCount={props.saveCount}
      />
    </CardContentContainer>
  )

  return <StyledCard id="printer-widget-container" content={CardContent()} />
}

export default PrinterWidget
