import { JarvisAuthProvider } from "@jarvis/web-http"
import { useToast } from "@veneer/core"
import { AES, enc } from "crypto-js"
import { useFlags } from "launchdarkly-react-client-sdk"
import React, { useCallback, useEffect, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { DeviceProps } from "../../../mfe/types/device"
import { i18n } from "../../assets/I18n"
import Images from "../../assets/images"
import AccountNameModal from "../../components/AccountNameModal"
import CloudDestinationModal from "../../components/CloudDestinationModal"
import CloudFormSection from "../../components/CloudFormSection"
import DialogModal from "../../components/DialogModal"
import EmailFormSection from "../../components/EmailFormSection"
import FileTypeFormSection from "../../components/FileTypeFormSection"
import FolderTreeModal from "../../components/FolderTreeModal"
import Loader from "../../components/Loader"
import Overlay from "../../components/Overlay"
import SharePointPathModal from "../../components/SharePointPathModal"
import ShortcutName from "../../components/ShortcutName"
import {
  ScanToCloudIcon,
  ScanToEmailIcon,
} from "../../components/SolutionItem/styles"
import { ProviderAction } from "../../data/enum/cloudTable"
import {
  FileTypeOptions,
  ocrLanguageConfigOptions,
} from "../../data/enum/fileType"
import {
  getProvidersList,
  providers,
  ProvidersOptions,
} from "../../data/enum/providers"
import {
  CapabilityItem,
  ConnectorData,
  Site,
} from "../../data/schemas/connector"
import {
  FolderId,
  FolderItem,
  FolderNameRequest,
} from "../../data/schemas/folders"
import {
  RepositoryConfig,
  ShortcutItem,
  SiteIdItem,
} from "../../data/schemas/shortcut"
import { uiData } from "../../helpers/dataCollection"
import { entitlementGenerator } from "../../helpers/entitlementGenerator"
import { fileTypeHandler } from "../../helpers/fileTypeHelper"
import {
  handleSigninMessageEvent,
  openSigninWindow,
} from "../../helpers/thirdPartyAuth"
import useTenantApi from "../../hooks/api/useTenantApi"
import useConnectorValidation from "../../hooks/validators/useConnectorValidation"
import useEmailValidation from "../../hooks/validators/useEmailValidation"
import usePinValidation from "../../hooks/validators/usePinValidation"
import useTitleValidation from "../../hooks/validators/useTitleValidation"
import { FormContainer, StyledCustomImage, StyledTitleIcon } from "./styles"
type ShortcutCreationFormProps = {
  stack: number
  authProvider: JarvisAuthProvider
  printer: DeviceProps
  container: HTMLElement
  sendUiEvent: { (uiDataObj: object): void }
  organizationName: string
}
const getRedirectURI = (stack: number) =>
  ({
    0: "https://smb.pie.portalshell.int.hp.com/scan-destinations/connectors/callback",
    1: "https://smb.pie.portalshell.int.hp.com/scan-destinations/connectors/callback",
    2: "https://smb.stage.portalshell.int.hp.com/scan-destinations/connectors/callback",
    3: "https://admin.hpsmart.com/scan-destinations/connectors/callback",
  }[stack])
const ShortcutCreationForm = (props: ShortcutCreationFormProps) => {
  const { smbSolutionItemRedesign } = useFlags()
  const history = useHistory()
  const location = useLocation()
  const { base, shortcutId } = useParams()
  const destinationType = location.pathname.split("/").slice(-2)[0]
  const cloudId = props.printer.deviceId
  const deviceUuid = props.printer.identity.deviceUuid
  const {
    getDeviceSupportStatus,
    getShortcutById,
    updateShortcut,
    createShortcut,
    getCapabilities,
    getAuthUrl,
    getFoldersNames,
    getAdminSitesSharepoint,
    error,
    isLoading,
    capabilities,
    folderNameItems,
    siteItem,
    sharepointRequest,
    setCapabilities,
  } = useTenantApi(props.stack, props.authProvider)
  const { addToast } = useToast()
  const { title, setTitle, titleError } = useTitleValidation()
  const { email, setEmail, emailError } = useEmailValidation()
  const {
    newAccountName,
    setNewAccountName,
    newAccountNameError,
  } = useConnectorValidation()
  const {
    pin,
    setPin,
    setPinConfirmation,
    pinConfirmation,
    pinError,
    validatePin,
    formatInputValue,
  } = usePinValidation()
  const [isFetchingShortcut, setFetchingShortcut] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showDeleteProviderModal, setShowDeleteProviderModal] = useState(false)
  const [entitled, setEntitledStatus] = useState(false)
  const [showAccountNameModal, setShowAccountNameModal] = useState<boolean>(
    false
  )
  const [
    accountNameModalEditMode,
    setAccountNameModalEditMode,
  ] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [siteItems, setSiteItems] = useState<Site[]>([])
  const [isLoadingSaveButton, setIsLoadingSaveButton] = useState<boolean>(false)
  const [loadingSetup, setLoadingSetup] = useState(false)
  const [loadingCapabilities, setLoadingCapabilities] = useState(false)
  const [showCloudDestinationModal, setShowCloudDestinationModal] = useState(
    false
  )
  const [showFolderTreeModal, setShowFolderTree] = useState(false)
  const [
    showSharePointPathModal,
    setShowSharePointPathModal,
  ] = useState<boolean>(false)
  const [selectedFolder, setSelectedFolder] = useState<FolderItem>(null)
  const [editFolder, setEditFolder] = useState("")
  const [editSite, setEditSite] = useState("")
  const [selectedSharepointSite, setSelectedSharepointSite] = useState<Site>(
    null
  )
  const [selectedSiteName, setSelectedSiteName] = useState<string>("")
  const [errorWithCapabilities, setErrorWithCapabilities] = useState(false)
  const [
    successfullyUpdatedAccountName,
    setSuccessfullyUpdatedAccountName,
  ] = useState(false)
  const [
    successfullyAddedAccountName,
    setSuccessfullyAddedAccountName,
  ] = useState(false)
  const [triggerAccountSignin, setTriggerAccountSignin] = useState(false)
  const [isSecurityPinToggleActive, setIsSecurityPinToggleActive] = useState(
    false
  )
  const [isFileNameAdded, setIsFileNameAdded] = useState(false)
  const [
    recentlyLoggedInProviderName,
    setRecentlyLoggedInProviderName,
  ] = useState("")
  const [selectedProviderType, setSelectedProviderType] = useState("")
  const [
    currentProviderNameToDelete,
    setCurrentProviderNameToDelete,
  ] = useState("")
  const [currentProviderType, setCurrentProviderType] = useState("")
  const [selectedFileType, setSelectedFileType] = useState("jpeg")
  //TODO: use device's default content language value when possible
  const [selectedContentLanguage, setSelectedContentLanguage] = useState(
    "en-US"
  )
  const [currentShortcut, setCurrentShortcut] = useState<ShortcutItem>()
  const [
    currentProviderItem,
    setCurrentProviderItem,
  ] = useState<CapabilityItem>()
  const [providersSelectedList, setProvidersSelectedList] = useState<
    ConnectorData[]
  >([])
  const [repositoryConfigList, setRepositoryConfigList] = useState<
    RepositoryConfig[]
  >([])
  const [subject, setSubject] = useState(
    i18n.assetsProvider.getText(
      "pages.ShortcutCreation.emailSection.defaultSubjectText",
      {
        businessName: `[${
          props.organizationName ??
          i18n.assetsProvider.getText(
            "pages.ShortcutCreation.emailSection.businessName"
          )
        }]`,
      }
    )
  )
  const [body, setBody] = useState(
    i18n.assetsProvider.getText(
      "pages.ShortcutCreation.emailSection.defaultBodyText",
      {
        businessName: `[${
          props.organizationName ??
          i18n.assetsProvider.getText(
            "pages.ShortcutCreation.emailSection.businessName"
          )
        }]`,
      }
    )
  )
  const [isLegacyShortcut, setIsLegacyShortcut] = useState(false)
  const saveButtonCondition =
    !title ||
    Boolean(titleError) ||
    ((!email || Boolean(emailError)) && destinationType === "email") ||
    (Object.keys(providersSelectedList).length === 0 &&
      destinationType === "cloud")
  const openFolderTreeModal = useCallback((provider: CapabilityItem) => {
    setCurrentProviderItem(provider)
    setShowFolderTree(true)
  }, [])
  const closeFolderTreeModal = useCallback(() => {
    setShowFolderTree(false)
  }, [])
  const onSelectedFolderHandler = useCallback(
    (folder: FolderItem) => {
      if (editFolder) {
        const index = providersSelectedList.findIndex(
          (provider) =>
            provider.connector.connectorId === currentProviderItem.connectorId
        )
        providersSelectedList[index].folder = { ...folder, isValid: true }
        setProvidersSelectedList(providersSelectedList)
        setShowFolderTree(false)
        setEditFolder("")
        return
      }
      if (
        !providersSelectedList.find(
          (provider) =>
            provider.connector.connectorName ===
            currentProviderItem.connectorName
        )
      ) {
        setRecentlyLoggedInProviderName(currentProviderItem.connectorName)
      }
      setSelectedFolder({ ...folder, isValid: true })
      setShowFolderTree(false)
    },
    [currentProviderItem, providersSelectedList, editFolder]
  )
  const getCurrentProviderItem = useCallback((provider: CapabilityItem) => {
    setCurrentProviderItem(provider)
  }, [])
  const onSaveSiteHandler = useCallback(
    (site: Site) => {
      if (editSite) {
        const index = providersSelectedList.findIndex(
          (provider) =>
            provider.connector.connectorId === currentProviderItem.connectorId
        )
        providersSelectedList[index].site = { ...site }
        setProvidersSelectedList(providersSelectedList)
        setShowSharePointPathModal(false)
        setEditSite("")
        return
      }
      if (
        !providersSelectedList.find(
          (provider) =>
            provider.connector.connectorName ===
            currentProviderItem.connectorName
        )
      ) {
        setRecentlyLoggedInProviderName(currentProviderItem.connectorName)
      }
      setSelectedSharepointSite({ ...site })
      setShowSharePointPathModal(false)
      setLoadingSetup(false)
    },
    [currentProviderItem, editSite, providersSelectedList]
  )

  const handleChange = useCallback((option: any) => {
    setSelectedSiteName(option.label)
    const site: Site = {
      displayName: option.label,
      webUrl: option.value,
      id: option.id,
    }
    setSelectedSharepointSite(site)
  }, [])

  useEffect(() => {
    if (selectedSiteName.length) {
      setIsDisabled(false)
    }
  }, [selectedSiteName.length])

  const onCloseFolder = useCallback(() => {
    setShowFolderTree(false)
    if (editFolder) {
      setEditFolder("")
      setShowCloudDestinationModal(false)
      return
    }
    setShowCloudDestinationModal(true)
  }, [editFolder])
  const addProviderToTable = useCallback(
    (item: CapabilityItem) => {
      let providers = [...providersSelectedList]
      if (item.type !== "sharepoint") {
        providers.push({ connector: item, folder: selectedFolder })
        setProvidersSelectedList(providers)
        setSelectedFolder(null)
      } else {
        providers.push({ connector: item, site: selectedSharepointSite })
        setProvidersSelectedList(providers)
        setSelectedSharepointSite(null)
      }
    },
    [providersSelectedList, selectedFolder, selectedSharepointSite]
  )
  const buildShortcutObject = () => {
    const repositories = []
    if (destinationType === "cloud") {
      const providersSelected = providersSelectedList
      const folderIdList = []
      const siteIdList = []
      for (const provider of providersSelected.values()) {
        if (provider.folder) {
          const folderId = provider.folder.id
          folderIdList.push(folderId)
          repositories.push({
            type: provider.connector.type,
            //TODO: Change it when we are able to select folders
            destinations: ["HP Smart"],
            connectorId: provider.connector.connectorId,
            connectorName: provider.connector.connectorName,
            folderList: [folderId],
          })
        } else if (provider.site) {
          const siteID = provider.site.id
          siteIdList.push(siteID)
          repositories.push({
            type: provider.connector.type,
            destinations: ["HP Smart"],
            connectorId: provider.connector.connectorId,
            connectorName: provider.connector.connectorName,
            sites: [siteID],
          })
        }
      }
    }
    const shortcut = {
      category: destinationType === "cloud" ? ["save"] : [destinationType],
      pin: isSecurityPinToggleActive && validatePin() ? pin : undefined,
      smartTask: {
        fileType: ["standardpdf", "jpeg"].includes(selectedFileType)
          ? selectedFileType === "standardpdf"
            ? "pdf"
            : selectedFileType
          : undefined,
        jobName: title.trim(),
        smartTaskConfig: {
          repositories: destinationType === "cloud" ? repositories : undefined,
          email:
            destinationType === "email"
              ? {
                  tos: email.toLowerCase().split(", "),
                  subject: subject,
                  message: body,
                }
              : undefined,
          ocr: ["searchablepdf", "docx", "txt"].includes(selectedFileType)
            ? {
                ocrLanguage: selectedContentLanguage,
                ocrOutputFileType:
                  selectedFileType === "searchablepdf"
                    ? "pdf"
                    : selectedFileType,
                smartName: isFileNameAdded,
              }
            : undefined,
        },
      },
    }
    return shortcut
  }
  const removeProviderFromSelectedList = (connectorName: string) => {
    let updatedProvidersSelected = providersSelectedList.filter((provider) => {
      return provider.connector.connectorName !== connectorName
    })
    setProvidersSelectedList(updatedProvidersSelected)
    setCurrentProviderNameToDelete("")
    setShowDeleteProviderModal(false)
  }
  const getAuthIdByConnectorId = (providerItem: CapabilityItem) => {
    if (capabilities.length > 0) {
      const providerSelected: CapabilityItem = capabilities.find(
        (provider) => provider.connectorId === providerItem.connectorId
      )
      setCurrentProviderItem(providerSelected)
    }
  }

  const handleProviderActions = (
    providerItem: ConnectorData,
    actionType: ProviderAction
  ) => {
    switch (actionType) {
      case ProviderAction.EditAccountName:
        getAuthIdByConnectorId(providerItem?.connector)
        setAccountNameModalEditMode(true)
        setShowAccountNameModal(true)
        break
      case ProviderAction.ChangeFolderPath:
        setEditFolder(providerItem?.folder?.id)
        openFolderTreeModal(providerItem?.connector)
        break
      case ProviderAction.ChangeSite:
        setLoadingSetup(true)
        getCurrentProviderItem(providerItem?.connector)
        siteEdition(providerItem?.connector?.connectorId)
        setEditSite(providerItem?.site?.id)
        break
      case ProviderAction.RemoveAccount:
        setCurrentProviderNameToDelete(providerItem?.connector?.connectorName)
        setCurrentProviderType(providers[providerItem?.connector?.type].name)
        setShowDeleteProviderModal(true)
    }
  }
  const sendDataAnalytics = (shortcut: ShortcutItem) => {
    props.sendUiEvent({
      ...uiData.CreationForm.shortcutNameText,
      screenName:
        destinationType === "cloud" ? "ScanCloudSetUp" : "ScanEmailSetUp",
      controlDetail: shortcut.smartTask.jobName ? "Provided" : "Blank",
    })
    if (destinationType === "cloud") {
      shortcut.smartTask.smartTaskConfig.repositories.forEach((item) =>
        props.sendUiEvent({
          ...uiData.CreationForm.selectCloudDestinationDropDown,
          controlDetail: item.type,
        })
      )
    } else {
      props.sendUiEvent({
        ...uiData.CreationForm.editEmailInfoText,
        controlDetail: shortcut.smartTask.smartTaskConfig.email.tos
          ? "Provided"
          : "Blank",
      })
    }
    props.sendUiEvent({
      ...uiData.CreationForm.selectFileTypeDropDown,
      screenName:
        destinationType === "cloud" ? "ScanCloudSetUp" : "ScanEmailSetUp",
      controlDetail: fileTypeHandler(shortcut),
    })
    if (entitled) {
      shortcut.pin
        ? props.sendUiEvent({
            ...uiData.CreationForm.toggleEnableSecurityPin,
            screenName:
              destinationType === "cloud" ? "ScanCloudSetUp" : "ScanEmailSetUp",
          })
        : props.sendUiEvent({
            ...uiData.CreationForm.toggleDisableSecurityPin,
            screenName:
              destinationType === "cloud" ? "ScanCloudSetUp" : "ScanEmailSetUp",
          })
    }
  }
  const handleSaveButtonClick = async () => {
    try {
      setIsLoadingSaveButton(true)
      const shortcut = buildShortcutObject()
      if (shortcutId) {
        await updateShortcut(shortcutId, shortcut as ShortcutItem)
      } else {
        await createShortcut(deviceUuid, shortcut as ShortcutItem)
        sendDataAnalytics(shortcut as ShortcutItem)
        props.sendUiEvent({
          ...uiData.CreationForm.btnShortcutFormSave,
          screenName:
            destinationType === "cloud" ? "ScanCloudSetUp" : "ScanEmailSetUp",
        })
      }
      history.push(
        `${base}/${cloudId}/scan-destinations?tab=${destinationType}`
      )
      sessionStorage.setItem("creationSuccess", "created")
      props.sendUiEvent({
        ...uiData.CreationForm.successToastSave,
        screenName:
          destinationType === "cloud"
            ? "ScanCloudSetupSuccessToast"
            : "ScanEmailSetupSuccessToast",
      })
      setIsLoadingSaveButton(false)
    } catch (error) {
      addToast({
        id: "smb-scan-destinations-creation-error",
        type: "negative",
        text: i18n.assetsProvider.getText(
          "pages.ScanDestinations.toasts.saveChangesError"
        ),
      })
      props.sendUiEvent({
        ...uiData.CreationForm.errorToastSave,
        screenName:
          destinationType === "cloud"
            ? "ScanCloudSetupErrorToast"
            : "ScanEmailSetupErrorToast",
      })
      setIsLoadingSaveButton(false)
    }
  }
  const retryCapabilities = () => {
    setLoadingCapabilities(true)
    getCapabilities()
  }

  const destinationFormSection = {
    email: (
      <EmailFormSection
        emailFieldLabel={i18n.assetsProvider.getText(
          "pages.ShortcutCreation.emailSection.to"
        )}
        emailValue={email}
        hasEmailErrors={emailError !== ""}
        emailHelperText={emailError}
        onChangeEmail={(value) => setEmail(value)}
        subjectFieldLabel={i18n.assetsProvider.getText(
          "pages.ShortcutCreation.emailSection.subject"
        )}
        subjectValue={subject}
        onChangeSubject={(value) => setSubject(value)}
        bodyFieldLabel={i18n.assetsProvider.getText(
          "pages.ShortcutCreation.emailSection.body"
        )}
        bodyValue={body}
        onChangeBody={(value) => setBody(value)}
      />
    ),
    cloud: (
      <>
        <CloudFormSection
          selectedCloudDestination={selectedProviderType}
          onSelectCloudDestination={(value: ProvidersOptions) =>
            setSelectedProviderType(value.value)
          }
          onAddButtonPress={() => {
            setShowCloudDestinationModal(true)
          }}
          providerOptions={getProvidersList(entitled)}
          tableData={[...providersSelectedList]}
          handleProviderActions={handleProviderActions}
          isDisabled={loadingSetup}
          isLoadingTable={loadingSetup}
          isLoadingCapabilities={loadingCapabilities}
          errorWithCapabilities={errorWithCapabilities}
          onRetry={retryCapabilities}
          capabilities={capabilities}
          folderNameItems={{
            folderInfoList: providersSelectedList?.map((connector) => {
              return {
                folderId: connector?.folder?.id,
                isValid: connector?.folder?.isValid,
                folderName: connector?.folder?.name,
              }
            }),
          }}
          siteItem={siteItem?.map((site) => {
            return {
              displayName: site.displayName,
              id: site.id,
              webUrl: site.webUrl,
            }
          })}
          repositoryConfigList={repositoryConfigList}
          setCurrentProviderType={setCurrentProviderType}
          setNewAccountName={setNewAccountName}
          setTriggerAccountSignin={setTriggerAccountSignin}
          isLegacyShortcut={isLegacyShortcut}
        />
        <DialogModal
          show={showDeleteProviderModal}
          titleIcon={<StyledTitleIcon filled size={46} />}
          title={i18n.assetsProvider.getText(
            "pages.ShortcutCreation.cloudSection.dialogues.removeDestinationFolder"
          )}
          upDescription={i18n.assetsProvider.getText(
            "pages.ShortcutCreation.cloudSection.dialogues.removeDestinationTextNew"
          )}
          description={i18n.assetsProvider.getText(
            "pages.ShortcutCreation.cloudSection.dialogues.removeDestinationSmartAccessNoProviderName"
          )}
          buttons={{
            firstLabel: i18n.assetsProvider.getText("common.remove"),
            firstColorScheme: "negative",
            firstFunction: () => {
              removeProviderFromSelectedList(currentProviderNameToDelete)
            },
            secondLabel: i18n.assetsProvider.getText("common.cancel"),
            secondFunction: () => {
              setCurrentProviderNameToDelete("")
              setShowDeleteProviderModal(false)
            },
          }}
          onClose={() => setShowDeleteProviderModal(false)}
        />
        <AccountNameModal
          show={showAccountNameModal}
          providerSelected={currentProviderItem}
          newAccountName={newAccountName}
          onClose={() => setShowAccountNameModal(false)}
          success={(editMode) => {
            editMode
              ? setSuccessfullyUpdatedAccountName(true)
              : setSuccessfullyAddedAccountName(true)
          }}
          onCancel={(editMode) => {
            setShowAccountNameModal(false)
            if (!editMode) {
              setShowCloudDestinationModal(true)
            }
          }}
          onChangeName={(newName: string) => setNewAccountName(newName)}
          newAccountNameError={newAccountNameError !== ""}
          stack={props.stack}
          authProvider={props.authProvider}
          repositories={capabilities}
          editMode={accountNameModalEditMode}
        />
      </>
    ),
  }[destinationType]
  const getCapabilityItem = async (connectorName: string) => {
    const repositories = await getCapabilities()
    const item: CapabilityItem = repositories.find(
      (provider: CapabilityItem) => {
        return provider.connectorName
          ? provider.connectorName === connectorName
          : undefined
      }
    )
    return item
  }

  const getSharepointSites = useCallback(
    async (connectorId: string) => {
      try {
        const sharepointResponse: Site[] = await getAdminSitesSharepoint(
          connectorId
        )
        const sharepointItem: Site[] = sharepointResponse.map((item: Site) => {
          return {
            displayName: item.displayName,
            webUrl: item.webUrl,
            id: item.id,
          }
        })
        setSiteItems(sharepointResponse)
        return sharepointItem
      } catch (error) {
        addToast({
          id: "smb-scan-destinations-authorization-error",
          type: "negative",
          text: i18n.assetsProvider.getText(
            "pages.ScanDestinations.toasts.somethingWentWrong"
          ),
        })
      }
    },
    [addToast, getAdminSitesSharepoint]
  )

  const siteEdition = useCallback(
    async (connectorId: string) => {
      const response: Site[] = await getAdminSitesSharepoint(connectorId)
      await getSharepointSites(connectorId)
      setSiteItems(response)
      response.find((selectedItem: Site) => selectedItem.id === editSite)
      if (response.length > 1) {
        setShowSharePointPathModal(true)
        setLoadingSetup(false)
      } else {
        return
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getAdminSitesSharepoint]
  )

  const setupProvider = async (
    currentProviderType: string,
    connectorName: string
  ) => {
    try {
      setLoadingSetup(true)
      const authorization = await getAuthUrl({
        redirectURI: getRedirectURI(props.stack),
        destinationType: currentProviderType,
        connectorName: newAccountName,
      })
      if (authorization.signinURL) {
        const childWin = openSigninWindow(authorization.signinURL)
        const checkWinStatus = setInterval(async () => {
          try {
            if (childWin.closed) {
              setLoadingSetup(true)
              clearInterval(checkWinStatus)
              let item: CapabilityItem = await getCapabilityItem(connectorName)
              if (item.type === "sharepoint") {
                const response = await getAdminSitesSharepoint(item.connectorId)
                await getSharepointSites(item.connectorId)
                getCurrentProviderItem(item)
                setLoadingSetup(false)
                const sites: Site[] = response
                setSiteItems(sites)
                if (sites.length > 1) {
                  setShowSharePointPathModal(true)
                } else {
                  addProviderToTable(item)
                }
              } else {
                openFolderTreeModal(undefined)
                setLoadingSetup(false)
                if (item) {
                  openFolderTreeModal(item)
                  setSelectedProviderType("")
                  setRecentlyLoggedInProviderName(connectorName)
                } else {
                  let timesRetried = 0
                  const retryCapabilityItem = setInterval(async () => {
                    item = await getCapabilityItem(connectorName)
                    if (item) {
                      openFolderTreeModal(item)
                      setSelectedProviderType("")
                      setRecentlyLoggedInProviderName(connectorName)
                      clearInterval(retryCapabilityItem)
                    }
                    if (timesRetried === 2 && !item) {
                      closeFolderTreeModal()
                      clearInterval(retryCapabilityItem)
                      addToast({
                        id: "smb-scan-destinations-authorization-error",
                        type: "negative",
                        text: i18n.assetsProvider.getText(
                          "pages.ScanDestinations.toasts.somethingWentWrong"
                        ),
                      })
                    }
                    timesRetried++
                  }, 2000)
                }
              }
            }
          } catch {
            setLoadingSetup(false)
            setSelectedProviderType("")
            setNewAccountName("")
            clearInterval(checkWinStatus)
          }
        }, 500)
      }
    } catch (error) {
      addToast({
        id: "smb-scan-destinations-authorization-error",
        type: "negative",
        text: i18n.assetsProvider.getText(
          "pages.ScanDestinations.toasts.somethingWentWrong"
        ),
      })
    } finally {
      setLoadingSetup(false)
    }
  }
  useEffect(() => {
    const beforeSetupProvider = async (
      currentProviderType: string,
      newAccountName: string
    ) => {
      if (successfullyAddedAccountName) {
        setShowAccountNameModal(false)
        setupProvider(currentProviderType, newAccountName)
      }
    }
    beforeSetupProvider(currentProviderType, newAccountName)
    setSuccessfullyAddedAccountName(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullyAddedAccountName])
  useEffect(() => {
    const reauthenticateProvider = async (
      currentProviderType: string,
      newAccountName: string
    ) => {
      if (triggerAccountSignin) {
        setupProvider(currentProviderType, newAccountName)
      }
    }
    reauthenticateProvider(currentProviderType, newAccountName)
    setTriggerAccountSignin(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerAccountSignin])
  useEffect(() => {
    const updateList = async () => {
      if (successfullyUpdatedAccountName) {
        setShowAccountNameModal(false)
        setLoadingSetup(true)
        const oldSelectedProviderIndex = providersSelectedList.findIndex(
          (provider) =>
            provider.connector.connectorId === currentProviderItem.connectorId
        )
        const oldProviderSelected = {
          ...providersSelectedList[oldSelectedProviderIndex],
        }
        const upProvider = {
          ...oldProviderSelected.connector,
          connectorName: newAccountName,
        }
        if (providersSelectedList.some((item) => item.folder)) {
          providersSelectedList[oldSelectedProviderIndex] = {
            connector: upProvider,
            folder: oldProviderSelected.folder,
          }
        }
        if (providersSelectedList.some((item) => item.site)) {
          providersSelectedList[oldSelectedProviderIndex] = {
            connector: upProvider,
            site: oldProviderSelected.site,
          }
        }
        let updatedCapabilities = capabilities.filter((provider) => {
          return provider.connectorId !== currentProviderItem.connectorId
        })
        updatedCapabilities.push(upProvider)
        setProvidersSelectedList(providersSelectedList)
        setCurrentProviderItem(upProvider)
        setCapabilities(updatedCapabilities)
        setLoadingSetup(false)
        setSuccessfullyUpdatedAccountName(false)
      }
    }
    updateList()
  }, [
    capabilities,
    currentProviderItem,
    newAccountName,
    providersSelectedList,
    setCapabilities,
    successfullyUpdatedAccountName,
  ])
  useEffect(() => {
    if (currentProviderItem?.connectorName) {
      setNewAccountName(currentProviderItem.connectorName)
    }
  }, [currentProviderItem?.connectorName, setNewAccountName])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { isSupported } = await getDeviceSupportStatus(deviceUuid)
        if (!isSupported || sessionStorage.getItem("entitlement") == null) {
          history.push(`${base}`)
        } else {
          setEntitledStatus(
            AES.decrypt(
              sessionStorage.getItem("entitlement"),
              entitlementGenerator.entitlement
            ).toString(enc.Utf8) === "true"
          )
        }
      } catch (error) {
        history.push(`${base}`)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    const checkRecentlyLoggedInProvider = async () => {
      const recentlyLoggedInProvider: CapabilityItem = capabilities.find(
        (provider) => provider.connectorName === recentlyLoggedInProviderName
      )
      const providerNotOnTable = !providersSelectedList.find(
        (item) =>
          item.connector?.connectorName ===
          recentlyLoggedInProvider?.connectorName
      )
      if (
        recentlyLoggedInProvider &&
        recentlyLoggedInProvider.isAuthorized &&
        providerNotOnTable
      ) {
        setSelectedProviderType("")
        setRecentlyLoggedInProviderName("")
        addProviderToTable(recentlyLoggedInProvider)
      }
    }
    if (selectedFolder || selectedSharepointSite) {
      checkRecentlyLoggedInProvider()
    }
  }, [
    addProviderToTable,
    capabilities,
    getCapabilities,
    providersSelectedList,
    recentlyLoggedInProviderName,
    setSelectedProviderType,
    selectedFolder,
    selectedSharepointSite,
  ])
  useEffect(() => {
    if (destinationType === "cloud") {
      if (capabilities.length === 0) {
        if (Object.keys(error).length === 0) {
          setLoadingCapabilities(true)
          getCapabilities()
          setErrorWithCapabilities(false)
        } else {
          setLoadingCapabilities(false)
          setErrorWithCapabilities(true)
        }
      } else {
        setLoadingCapabilities(false)
      }
      const eventHandler = (event: MessageEvent) =>
        handleSigninMessageEvent(event, getCapabilities)
      window.addEventListener("message", eventHandler)
      return () => window.removeEventListener("message", eventHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationType, capabilities, error])
  useEffect(() => {
    const fetchShortcut = async () => {
      if (shortcutId) {
        setFetchingShortcut(true)
        const shortcut: ShortcutItem = await getShortcutById(shortcutId)
        setCurrentShortcut(shortcut)
        const smartTaskConfig = shortcut.smartTask.smartTaskConfig
        setTitle(shortcut.smartTask.jobName)
        if (
          smartTaskConfig.repositories &&
          smartTaskConfig.repositories
            .map((item) => item.folderList)
            .includes(undefined)
        ) {
          setIsLegacyShortcut(true)
        }
        if (shortcut?.pin) {
          setPin(shortcut?.pin)
          setPinConfirmation(shortcut?.pin)
          setIsSecurityPinToggleActive(true)
        }
        if (shortcut.smartTask?.smartTaskConfig?.ocr?.smartName) {
          setIsFileNameAdded(true)
        }
        if (smartTaskConfig.repositories) {
          const repositoryConfigs = []
          const connectorDataList: ConnectorData[] = []
          smartTaskConfig.repositories.forEach((repository) => {
            const connectorData: ConnectorData = {
              connector: repository as CapabilityItem,
            }
            connectorDataList.push(connectorData)
            repositoryConfigs.push({
              connectorId: repository.connectorId,
              connectorName: repository.connectorName,
              folderList: repository.folderList,
              sites: repository.sites,
            })
          })
          setProvidersSelectedList(connectorDataList)
          setRepositoryConfigList(repositoryConfigs)
        }
        if (smartTaskConfig.email) {
          setEmail(smartTaskConfig.email.tos.join(", "))
          setSubject(smartTaskConfig.email.subject)
          setBody(smartTaskConfig.email.message)
        }
        if (shortcut.smartTask?.fileType) {
          if (shortcut.smartTask.fileType === "pdf") {
            setSelectedFileType("standardpdf")
          } else {
            setSelectedFileType("jpeg")
          }
        } else {
          setSelectedFileType(
            shortcut.smartTask.smartTaskConfig.ocr.ocrOutputFileType === "pdf"
              ? "searchablepdf"
              : shortcut.smartTask?.smartTaskConfig.ocr.ocrOutputFileType
          )
        }
        if (shortcut.smartTask?.smartTaskConfig?.ocr?.ocrLanguage) {
          setSelectedContentLanguage(
            shortcut.smartTask.smartTaskConfig.ocr.ocrLanguage
          )
        } else {
          setSelectedContentLanguage("en-US")
        }
      }
      setFetchingShortcut(false)
    }
    fetchShortcut()
    // TODO FIXME: when adding the hook functions to this array
    // it causes to infintly trigger the useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const ensureFolderNameList = useCallback(async () => {
    let folderIdList: FolderId[] = []
    let folderIdObj = {} as FolderNameRequest

    if (currentShortcut) {
      if (currentShortcut.smartTask.smartTaskConfig.repositories) {
        for (const repository of currentShortcut.smartTask.smartTaskConfig
          .repositories) {
          if (repository.folderList) {
            for (const folderItem of repository.folderList) {
              folderIdList.push({ id: folderItem.folderId })
            }
          }
        }
      }
    } else {
      return
    }
    folderIdObj["folderIds"] = folderIdList
    const getFoldersNameAsync = async (folderIdObj: {
      folderIds: FolderId[]
    }) => {
      await getFoldersNames(folderIdObj)
    }
    if (Object.keys(folderIdObj.folderIds).length) {
      await getFoldersNameAsync(folderIdObj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentShortcut])

  const ensureDisplayNameList = useCallback(async () => {
    let siteIdList: SiteIdItem[] = []
    let objList: Site[] = []

    if (currentShortcut) {
      if (currentShortcut.smartTask.smartTaskConfig.repositories) {
        for (const repository of currentShortcut.smartTask.smartTaskConfig
          .repositories) {
          if (repository.sites) {
            const response: Site[] = await getAdminSitesSharepoint(
              repository.connectorId
            )
            if (response.length) {
              for (const item of response) {
                objList.push(item)
              }
              return objList
            }
            for (const site of objList) {
              siteIdList.push({ siteID: site.id })
            }
          }
        }
      }
    } else {
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentShortcut])
  useEffect(() => {
    if (
      !(
        folderNameItems &&
        folderNameItems?.folderInfoList &&
        folderNameItems?.folderInfoList?.length
      )
    ) {
      ensureFolderNameList()
    }
    if (
      folderNameItems &&
      folderNameItems?.folderInfoList &&
      folderNameItems?.folderInfoList?.length
    ) {
      folderNameItems.folderInfoList.forEach((folderInforItem) => {
        currentShortcut.smartTask.smartTaskConfig.repositories.forEach(
          (value) => {
            if (
              value.folderList?.find(
                (folderValue) =>
                  folderValue.folderId === folderInforItem.folderId
              )
            ) {
              const providerIndex = providersSelectedList.findIndex(
                (connectorDataItem) =>
                  connectorDataItem.connector.connectorId === value.connectorId
              )
              if (providerIndex >= 0) {
                providersSelectedList[providerIndex].folder = {
                  id: folderInforItem.folderId,
                  name:
                    folderInforItem.folderId === value.connectorId
                      ? value.connectorName
                      : folderInforItem.folderName,
                  type: "",
                  isValid: folderInforItem.isValid,
                }
              }
            }
          }
        )
      })
      setProvidersSelectedList(providersSelectedList)
    }
  }, [
    folderNameItems,
    providersSelectedList,
    currentShortcut,
    ensureFolderNameList,
  ])
  useEffect(() => {
    if (!(siteItem && siteItem?.length)) {
      ensureDisplayNameList()
    }
    if (siteItem && siteItem?.length) {
      siteItem.forEach((siteDataItem) => {
        currentShortcut?.smartTask.smartTaskConfig.repositories.forEach(
          (value) => {
            if (
              value.sites?.find(
                (siteValue) => siteValue.siteID === siteDataItem.id
              )
            ) {
              const providerIndex = providersSelectedList.findIndex(
                (connectorDataItem) =>
                  connectorDataItem.connector.connectorId === value.connectorId
              )
              if (providerIndex >= 0) {
                providersSelectedList[providerIndex].site = {
                  id: siteDataItem.id,
                  displayName: siteDataItem.displayName,
                  webUrl: siteDataItem.webUrl,
                }
              }
            }
          }
        )
      })
      setProvidersSelectedList(providersSelectedList)
    }
  }, [currentShortcut, ensureDisplayNameList, providersSelectedList, siteItem])

  if (isLoading && isFetchingShortcut) {
    return <Loader />
  }
  return (
    <Overlay
      active={true}
      headerIcon={
        destinationType === "email" ? (
          <>
            {smbSolutionItemRedesign ? (
              <ScanToEmailIcon className="creationFormIcon">
                <img
                  src={Images.scanToEmail}
                  alt="Scan to email icon"
                  className="solutionItemVector"
                />
              </ScanToEmailIcon>
            ) : (
              <StyledCustomImage src={Images.email.toString()} size={36} />
            )}
          </>
        ) : (
          <>
            {smbSolutionItemRedesign ? (
              <ScanToCloudIcon className="creationFormIcon">
                <img
                  src={Images.scanToCloud}
                  alt="Scan to email icon"
                  className="solutionItemVector"
                />
              </ScanToCloudIcon>
            ) : (
              <StyledCustomImage src={Images.cloud.toString()} size={36} />
            )}
          </>
        )
      }
      headerTitle={
        destinationType === "email"
          ? i18n.assetsProvider.getText(
              "pages.ScanDestinations.scanToEmail.title"
            )
          : i18n.assetsProvider.getText(
              "pages.ScanDestinations.scanAndSave.title"
            )
      }
      footerButtons={[
        {
          appearance: "secondary",
          text: i18n.assetsProvider.getText("common.cancel"),
          onClick: () => {
            props.sendUiEvent({
              ...uiData.CreationForm.btnShortcutFormCancel,
              screenName:
                destinationType === "cloud"
                  ? "ScanCloudSetUp"
                  : "ScanEmailSetUp",
            })
            setShowCancelModal(true)
            props.sendUiEvent({
              ...uiData.CreationForm.btnShortcutOverlay,
              screenName:
                destinationType === "cloud"
                  ? "OverlayUnsavedChangesScanCloudSetup"
                  : "OverlayUnsavedChangesScanEmailSetup",
            })
          },
          disabled: isLoading,
        },
        {
          text: i18n.assetsProvider.getText("common.save"),
          loading: isLoadingSaveButton,
          disabled:
            entitled && isSecurityPinToggleActive
              ? !validatePin() || saveButtonCondition
              : saveButtonCondition,
          onClick: handleSaveButtonClick,
        },
      ]}
      container={props.container}
    >
      <FormContainer>
        <ShortcutName
          value={title}
          error={titleError !== ""}
          helperText={titleError}
          onChange={setTitle}
        />
        {destinationFormSection}
        <FileTypeFormSection
          isFileNameAdded={isFileNameAdded}
          onChangeFileNameAdded={(value) => {
            setIsFileNameAdded(value)
          }}
          selectedContentLanguage={selectedContentLanguage}
          selectedFileType={selectedFileType}
          onSelectFileType={(value: FileTypeOptions) =>
            setSelectedFileType(value.value)
          }
          onSelectContentLanguage={(value: ocrLanguageConfigOptions) =>
            setSelectedContentLanguage(value.value)
          }
          entitled={entitled}
          selectedFileHelperText={
            selectedFileType === "searchablepdf"
              ? i18n.assetsProvider.getText(
                  "pages.ShortcutCreation.settingsSection.typeOptions.searchablePDFDescription"
                )
              : selectedFileType === "docx"
              ? i18n.assetsProvider.getText(
                  "pages.ShortcutCreation.settingsSection.typeOptions.wordDocumentDescription"
                )
              : i18n.assetsProvider.getText(
                  "pages.ShortcutCreation.settingsSection.typeOptions.plainTextDescription"
                )
          }
          isSecurityPinToggleActive={isSecurityPinToggleActive}
          onChangeSecurityPinAdded={(value) =>
            setIsSecurityPinToggleActive(value)
          }
          securityPin={pin}
          onChangeSecurityPin={(value) => setPin(formatInputValue(value))}
          hasSecurityPinErrors={pinError !== ""}
          securityPinHelperText={
            pinError ||
            i18n.assetsProvider.getText(
              "pages.ShortcutCreation.settingsSection.securityPin.characterCountEightDigitsDisclaimer"
            )
          }
          securityPinConfirmation={pinConfirmation}
          onChangeSecurityPinConfirmation={(value) =>
            setPinConfirmation(formatInputValue(value))
          }
          onPinBlur={validatePin}
          securityPinMaxLength={8}
          pinError={pinError}
        />
      </FormContainer>
      <DialogModal
        show={showCancelModal}
        titleIcon={<StyledTitleIcon filled size={46} />}
        title={i18n.assetsProvider.getText(
          "pages.ShortcutCreation.dialogues.cancelShortcutTitle"
        )}
        description={i18n.assetsProvider.getText(
          "pages.ShortcutCreation.dialogues.cancelShortcutDescription"
        )}
        buttons={{
          firstLabel: i18n.assetsProvider.getText("common.leave"),
          firstFunction: () => {
            props.sendUiEvent({
              ...uiData.CreationForm.btnShortcutOverlayLeave,
              screenName:
                destinationType === "cloud"
                  ? "OverlayUnsavedChangesScanCloudSetup"
                  : "OverlayUnsavedChangesScanEmailSetup",
            })
            history.push(
              `${base}/${cloudId}/scan-destinations?tab=${destinationType}`
            )
          },
          secondLabel: i18n.assetsProvider.getText("common.cancel"),
          secondFunction: () => {
            props.sendUiEvent({
              ...uiData.CreationForm.btnShortcutOverlayCancel,
              screenName:
                destinationType === "cloud"
                  ? "OverlayUnsavedChangesScanCloudSetup"
                  : "OverlayUnsavedChangesScanEmailSetup",
            })
            setShowCancelModal(false)
          },
        }}
        onClose={() => setShowCancelModal(false)}
      />
      <CloudDestinationModal
        show={showCloudDestinationModal}
        addProviderButtonTitle={i18n.assetsProvider.getText(
          "pages.ShortcutCreation.cloudSection.addCloudDestinationNewAccount"
        )}
        providerListTitle={i18n.assetsProvider.getText(
          "pages.ShortcutCreation.cloudSection.dialogues.providersGetStartedMessage"
        )}
        modalTitle={i18n.assetsProvider.getText(
          "pages.ShortcutCreation.cloudSection.addCloudStorageDestination"
        )}
        onClose={() => {
          setShowCloudDestinationModal(false)
        }}
        onSelectConnectorItem={async (selectedProvider: CapabilityItem) => {
          setShowCloudDestinationModal(false)
          if (selectedProvider.type !== "sharepoint") {
            openFolderTreeModal(selectedProvider)
          } else {
            setLoadingSetup(true)
            const response = await getAdminSitesSharepoint(
              selectedProvider.connectorId
            )
            await getSharepointSites(selectedProvider.connectorId)
            getCurrentProviderItem(selectedProvider)
            setLoadingSetup(false)
            const sites: Site[] = response
            setSiteItems(sites)
            if (sites.length > 1) {
              setShowSharePointPathModal(true)
            } else {
              addProviderToTable(selectedProvider)
            }
          }
        }}
        onAddNew={(providerType: string) => {
          setShowCloudDestinationModal(false)
          setNewAccountName("")
          setAccountNameModalEditMode(false)
          setShowAccountNameModal(true)
          setCurrentProviderType(providerType)
        }}
        cloudDestinationType={selectedProviderType}
        providersList={getProvidersList(entitled)}
        providersAlreadySelectedList={providersSelectedList}
        authenticatedConnectorItems={capabilities.filter(
          (connectorItem: CapabilityItem) => connectorItem?.isAuthorized
        )}
        onRetry={retryCapabilities}
        isLoading={loadingCapabilities}
      />
      <FolderTreeModal
        currentConnector={currentProviderItem}
        editSelectedFolder={editFolder}
        isVisible={showFolderTreeModal}
        onClose={onCloseFolder}
        onConfirm={onSelectedFolderHandler}
        stack={props.stack}
        authProvider={props.authProvider}
      />
      {sharepointRequest && (
        <SharePointPathModal
          show={showSharePointPathModal}
          siteOptions={siteItems}
          title={i18n.assetsProvider.getText(
            "pages.ShortcutCreation.cloudSection.selectSpPathTitle"
          )}
          subTitle={i18n.assetsProvider.getText(
            "pages.ShortcutCreation.cloudSection.selectSpPathSubtitle"
          )}
          onConfirm={() => {}}
          onClose={() => setShowSharePointPathModal(false)}
          onChange={handleChange}
          buttons={{
            primaryLabel: i18n.assetsProvider.getText("common.save"),
            primaryAppearance: "primary",
            primaryFunction: () => {
              onSaveSiteHandler(selectedSharepointSite)
            },
            disabled: isDisabled,
            secondaryLabel: i18n.assetsProvider.getText("common.cancel"),
            secondaryAppearance: "secondary",
            secondaryFunction: () => {
              setShowSharePointPathModal(false)
              setLoadingSetup(false)
            },
          }}
        />
      )}
    </Overlay>
  )
}
ShortcutCreationForm.defaultProps = { container: document.body }
export default ShortcutCreationForm
