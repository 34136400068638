import React from "react"
import { i18n } from "../../assets/I18n"
import Images from "../../assets/images"
import {
  CloudIcon,
  CloudText,
  EmailCounter,
  EmailCountNumber,
  EmailIcon,
  EmailText,
  SaveCounter,
  SaveCountNumber,
  ScanToCloudContainer,
  ScanToEmailContainer,
  ShortcutDataContainer,
} from "./styles"

type PrinterWidgetShortcutInfoProps = {
  emailCount: string
  saveCount: string
}

// "ShortcutCount" was left as an empty string, as it was already part of the translations, but a layout change was made that separated the counter string from the scan title and in order not to have to request the translations again, we left the "shortcutCount" string like this.
const PrinterWidgetShortcutInfo = (props: PrinterWidgetShortcutInfoProps) => (
  <ShortcutDataContainer id="shortcut-data-container">
    <ScanToEmailContainer>
      <EmailIcon>
        <img src={Images.scanToEmail} alt="Scan to email icon" />
      </EmailIcon>
      <EmailText data-testid="scan-to-email-printer-info-text">
        {i18n.assetsProvider.getText("common.scanToEmail", {
          shortcutCount: "",
        })}
      </EmailText>
      <EmailCounter emailCount={props.emailCount}>
        <EmailCountNumber
          data-testid="email-counter"
          emailCount={props.emailCount}
          id="email-count-number"
        >
          {props.emailCount}
        </EmailCountNumber>
      </EmailCounter>
    </ScanToEmailContainer>
    <ScanToCloudContainer>
      <CloudIcon>
        <img src={Images.scanToCloud} alt="Scan to cloud icon" />
      </CloudIcon>
      <CloudText data-testid="scan-to-cloud-printer-info-text">
        {i18n.assetsProvider.getText("common.scanToCloud", {
          shortcutCount: "",
        })}
      </CloudText>
      <SaveCounter saveCount={props.saveCount}>
        <SaveCountNumber saveCount={props.saveCount}>
          {props.saveCount}
        </SaveCountNumber>
      </SaveCounter>
    </ScanToCloudContainer>
  </ShortcutDataContainer>
)

export default PrinterWidgetShortcutInfo
