import React from "react"
import { i18n } from "../../assets/I18n"
import { MainContainer, NameInput, NameTitle } from "./styles"

type ShortcutNameProps = {
  value: string
  error: boolean
  helperText: string
  onChange: { (value: string): void }
}

const ShortcutName = (props: ShortcutNameProps) => {
  const CardContent = () => (
    <>
      <NameTitle>
        {i18n.assetsProvider.getText("pages.ShortcutCreation.nameFieldTitle")}
      </NameTitle>
      <NameInput
        id="shortcut-name-input"
        aria-label="shortcut name input"
        label={i18n.assetsProvider.getText(
          "pages.ShortcutCreation.entryFieldLabel"
        )}
        value={props.value}
        error={props.error}
        helperText={props.helperText}
        onChange={props.onChange}
        required
        autoFocus
      />
    </>
  )

  return <MainContainer content={CardContent()} />
}

export default ShortcutName
