import { Button, ButtonGroup, IconType } from "@veneer/core"
import React, { ReactNode } from "react"
import {
  CustomDescription,
  CustomTitle,
  CustomTitleText,
  CustomUpDescription,
  NoticeContainer,
  NoticeDescription,
  NoticeIcon,
  NoticeText,
  NoticeTitle,
  StyledModal,
} from "./styles"

type ButtonAppearance = "ghost" | "primary" | "secondary"

type ButtonColorScheme = "default" | "positive" | "negative" | "warning"

type DialogModalProps = {
  show: boolean
  title: string
  upDescription?: string
  description: string
  closeButton?: boolean
  buttons?: {
    firstLabel: string
    firstAppearance?: ButtonAppearance
    firstColorScheme?: ButtonColorScheme
    firstFunction: { (): void }
    secondLabel?: string
    secondAppearance?: ButtonAppearance
    secondColorScheme?: ButtonColorScheme
    secondFunction?: { (): void }
  }
  titleIcon?: ReactNode | IconType
  isLoading?: boolean
  onClose: { (): void }
  notice?: {
    title: string
    description: string
    icon?: ReactNode | IconType
  }
  isInfoModal?: boolean
}

const DialogModal = ({
  show,
  title,
  upDescription,
  description,
  closeButton,
  buttons,
  onClose,
  isLoading,
  titleIcon,
  notice,
  isInfoModal = false,
}: DialogModalProps) => {
  return (
    <StyledModal
      data-testid="dialog-modal"
      id="dialog-modal-container"
      onClose={onClose}
      isInfoModal={isInfoModal}
      show={show}
      closeButton={closeButton}
      footer={
        buttons && (
          <ButtonGroup>
            {buttons.secondLabel && (
              <Button
                id={`${buttons.secondLabel}-button`}
                disabled={isLoading}
                appearance={buttons.secondAppearance || "secondary"}
                colorScheme={buttons.secondColorScheme}
                onClick={buttons.secondFunction}
              >
                {buttons.secondLabel}
              </Button>
            )}
            <Button
              id={`${buttons.secondLabel}-button`}
              disabled={isLoading}
              appearance={buttons.firstAppearance}
              colorScheme={buttons.firstColorScheme}
              loading={isLoading}
              onClick={buttons.firstFunction}
            >
              {buttons.firstLabel}
            </Button>
          </ButtonGroup>
        )
      }
    >
      <CustomTitle id="dialog-modal-custom-title-container">
        {titleIcon}
        <CustomTitleText className="title-small">{title}</CustomTitleText>
      </CustomTitle>
      {upDescription !== undefined && (
        <CustomUpDescription isInfoModal={isInfoModal} className="body-large">
          {upDescription}
        </CustomUpDescription>
      )}
      <CustomDescription isInfoModal={isInfoModal} className="body-large">
        {description}
      </CustomDescription>
      {notice && (
        <NoticeContainer id="notice-container">
          <NoticeIcon>{notice.icon}</NoticeIcon>
          <NoticeText>
            <NoticeTitle>{notice.title}</NoticeTitle>
            <NoticeDescription>{notice.description}</NoticeDescription>
          </NoticeText>
        </NoticeContainer>
      )}
    </StyledModal>
  )
}

export default DialogModal
