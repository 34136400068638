import {
  CustomImage,
  IconFolder,
  IconGlobe,
  IconWarning,
  Tooltip,
} from "@veneer/core"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { i18n } from "../../assets/I18n"
import { providers } from "../../data/enum/providers"
import { Site } from "../../data/schemas/connector"
import { FolderInfoItem } from "../../data/schemas/folders"
import {
  FolderIdItem,
  RepositoryConfig,
  ShortcutItem,
  SiteIdItem,
} from "../../data/schemas/shortcut"
import { sort } from "../../helpers/table"
import {
  AuthenticationFailedContainer,
  CloudFormTable,
  EmailAddress,
  EmailAddressContainer,
  InvalidFolder,
  ProviderEmailText,
  ProviderFolderIconContainer,
  ProviderInfoContainer,
  ProviderInfoTypeEmailContainer,
  ProviderSiteIconContainer,
  ProviderText,
  ReauthorizeText,
  StyledModal,
  TitleContainer,
  TitleText,
} from "./styles"

type QuickLookModalProps = {
  show: boolean
  title: string
  emailsList?: string[]
  tableData?: any
  listOfCapabilitiesIds: string[]
  listOfInvalidFolderIds: string[]
  listOfFolderNameItems: FolderInfoItem[]
  listOfSiteItems: Site[]
  cloudId: string
  shortcutItem: ShortcutItem
  onClose: { (): void }
}

const QuickLookModal = ({
  show,
  title,
  emailsList,
  tableData,
  listOfCapabilitiesIds,
  listOfInvalidFolderIds,
  listOfFolderNameItems,
  listOfSiteItems,
  cloudId,
  shortcutItem,
  onClose,
}: QuickLookModalProps) => {
  const history = useHistory()
  const { base } = useParams()
  const [tableDataItems, setTableDataItems] = useState([])
  const [tableOrderType, setTableOrderType] = useState("ascending")
  const [folderNameItems, setFolderNameItems] = useState<FolderInfoItem[]>(
    listOfFolderNameItems
  )
  const [siteItems, setSiteItems] = useState<Site[]>(listOfSiteItems)

  useEffect(() => {
    setFolderNameItems(listOfFolderNameItems)
    setSiteItems(listOfSiteItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const providerNameAndIcon = (
    providerType: string,
    connectorName: string,
    connectorId: string,
    listOfCapabilitiesIds: string[]
  ) => {
    return (
      <ProviderInfoContainer key={`${connectorName}-provider-infor-contaner`}>
        <CustomImage
          src={providers[providerType].icon.toString()}
          size={36}
        ></CustomImage>
        <ProviderInfoTypeEmailContainer>
          <ProviderText className="label">
            {providers[providerType].name}
          </ProviderText>
          {listOfCapabilitiesIds.length &&
          !listOfCapabilitiesIds.includes(connectorId) ? (
            <>
              <IconWarning size={18} color="colorRed6" filled />
              <AuthenticationFailedContainer className="caption-small">
                {i18n.assetsProvider.getText(
                  "pages.ScanDestinations.folders.accountAuthFailedQuickLook"
                )}
              </AuthenticationFailedContainer>
            </>
          ) : (
            <ProviderEmailText className="caption-small">
              {connectorName}
            </ProviderEmailText>
          )}
        </ProviderInfoTypeEmailContainer>
      </ProviderInfoContainer>
    )
  }

  const providerFolders = (
    connectorId: string,
    listOfCapabilitiesIds: string[],
    listOfInvalidFolderIds: string[],
    listOfItemIds: FolderIdItem[],
    listOfSiteIds: SiteIdItem[],
    shortcutItemId: string
  ) => {
    let folderName = "HP Smart"
    let displayName = "HP Smart"
    // Regex to test if root folder is equal to UUID V4 that is used by providers
    const rootFolderRegex = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

    return (
      <ProviderInfoContainer>
        {listOfCapabilitiesIds.length &&
        !listOfCapabilitiesIds.includes(connectorId) ? (
          <ReauthorizeText
            className="label"
            onClick={() => {
              history.push(
                `${base}/${cloudId}/scan-destinations/${shortcutItemId}/cloud/edit`
              )
            }}
          >
            {i18n.assetsProvider.getText(
              "pages.ScanDestinations.folders.reauthenticateAccount"
            )}
          </ReauthorizeText>
        ) : listOfInvalidFolderIds &&
          listOfInvalidFolderIds.length &&
          listOfItemIds &&
          listOfItemIds.length &&
          listOfItemIds.some((element) =>
            listOfInvalidFolderIds.includes(element.folderId)
          ) ? (
          <>
            <IconFolder filled color="colorGray4" />
            <Tooltip
              arrow
              content={i18n.assetsProvider.getText(
                "pages.ScanDestinations.tooltip.folderRemovedWarning"
              )}
              placement="bottom-end"
              portal
            >
              <InvalidFolder className="label">
                {i18n.assetsProvider.getText(
                  "pages.ScanDestinations.folders.folderNotExist"
                )}
                <IconWarning size={24} color="colorRed6" filled />
              </InvalidFolder>
            </Tooltip>
          </>
        ) : (
          <>
            {shortcutItem.smartTask.smartTaskConfig.repositories.forEach(
              (shortcut) => {
                if (connectorId === shortcut.connectorId) {
                  if (shortcut.folderList) {
                    folderNameItems?.forEach((folder) => {
                      if (
                        shortcut.folderList &&
                        shortcut.folderList[0].folderId === folder.folderId
                      ) {
                        folderName = rootFolderRegex.test(folder?.folderName)
                          ? shortcut.connectorName
                          : folder.folderName
                      }
                    })
                  } else if (shortcut.sites) {
                    siteItems?.forEach((site) => {
                      if (
                        shortcut.sites &&
                        shortcut.sites[0].siteID === site.id
                      ) {
                        displayName = site.displayName
                      }
                    })
                  }
                }
              }
            )}
            {((!!listOfItemIds &&
              listOfItemIds.some((element) => element.folderId)) ||
              (shortcutItem.smartTask.smartTaskConfig.repositories
                .map((item) => item.folderList)
                .includes(undefined) &&
                !listOfSiteIds)) && (
              <>
                <ProviderFolderIconContainer>
                  <IconFolder filled color="colorBlue3" />
                </ProviderFolderIconContainer>
                <ProviderText>{folderName}</ProviderText>
              </>
            )}
            {!!listOfSiteIds && listOfSiteIds.some((site) => site.siteID) && (
              <>
                <ProviderSiteIconContainer>
                  <IconGlobe filled color="colorBlue3" />
                </ProviderSiteIconContainer>
                <ProviderText>{displayName}</ProviderText>
              </>
            )}
          </>
        )}
      </ProviderInfoContainer>
    )
  }

  const transformFunction = (value: RepositoryConfig) => {
    return value?.connectorName?.toLowerCase()
  }

  useEffect(() => {
    if (tableData && tableOrderType) {
      const sortedDataTable = sort(tableData, tableOrderType, transformFunction)
      setTableDataItems(createTableItems(sortedDataTable))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, tableOrderType])

  const handleSort = (_: any, { type }) => {
    setTableOrderType(type)
  }

  const createTableItems = (arrayToConvert: RepositoryConfig[]) => {
    return arrayToConvert.map((provider: RepositoryConfig) => ({
      type: providerNameAndIcon(
        provider.type,
        provider.connectorName,
        provider.connectorId,
        listOfCapabilitiesIds
      ),
      folders: providerFolders(
        provider.connectorId,
        listOfCapabilitiesIds,
        listOfInvalidFolderIds,
        provider.folderList,
        provider.sites,
        shortcutItem.id
      ),
    }))
  }

  return (
    <StyledModal
      id="quick-look-modal-container"
      closeButton
      onClose={onClose}
      show={show}
      wide={tableData.length ? "true" : "false"}
    >
      <TitleContainer id="quick-look-title-container">
        <TitleText className="title-small">{title}</TitleText>
      </TitleContainer>
      {emailsList.length ? (
        <EmailAddressContainer id="email-address-container">
          {emailsList.map((email, index) => {
            return (
              <EmailAddress
                className="subtitle-regular"
                key={`${email}${index}`}
              >{`${email};`}</EmailAddress>
            )
          })}
        </EmailAddressContainer>
      ) : undefined}
      {tableData.length ? (
        <CloudFormTable
          id="cloud-form-table"
          columns={[
            {
              id: "type",
              label: i18n.assetsProvider.getText(
                "pages.ShortcutCreation.cloudSection.tableLabels.cloudAccount"
              ),
            },
            {
              id: "folders",
              label: i18n.assetsProvider.getText(
                "pages.ShortcutCreation.cloudSection.tableLabels.destination"
              ),
              sortable: true,
            },
          ]}
          data={tableDataItems}
          loadingDataLength={3}
          onSort={handleSort}
          preferences={{
            sortBy: {
              id: "type",
              type: "ascending",
            },
            width: [
              { columnId: "type", width: "30%" },
              { columnId: "folders", width: "60%" },
            ],
          }}
        />
      ) : undefined}
    </StyledModal>
  )
}

export default QuickLookModal
