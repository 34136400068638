import { Card, Password, PasswordProps, Select } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const MainContainer = styled(Card)`
  display: grid;
  grid-template-rows: auto auto;

  #file-type-form-container {
    @media (max-width: ${tokens.mdMin}) {
      display: flex;
      flex-direction: column;
      width: fit-content;
    }
  }

  #security-pin-input-container {
    @media (max-width: ${tokens.mdMin}) {
      width: auto;
    }
  }

  #file-type-content-container {
    width: 50%;
    div {
      width: 100% !important;
    }
    @media (max-width: ${tokens.mdMin}) {
      width: 100%;
    }
  }
`
export const SettingsTitle = styled.h6`
  height: 56px;
  display: flex;
  align-items: center;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  padding-left: 23px;
  border-bottom: 1px solid ${tokens.colorGray2};
  @media (max-width: ${tokens.lgMin}) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  @media (max-width: ${tokens.smMin}) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`

export const FileTypeContainer = styled.div``

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 23px;
`

export const FileTypeTitle = styled.h6`
  padding-bottom: 23px;
  @media (max-width: ${tokens.lgMin}) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  @media (max-width: ${tokens.smMin}) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`

export const FileNameTitle = styled.h6`
  padding-bottom: 23px;
  @media (max-width: ${tokens.lgMin}) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  @media (max-width: ${tokens.smMin}) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`

export const PinTitle = styled.h6`
  padding-bottom: 23px;
`

export const FileTypeSelect = styled(Select)`
  padding-bottom: 23px;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  width: 100% !important;
`

export const ContentLanguageSelect = styled(Select)`
  padding-bottom: 23px;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  width: 40% !important;
  margin-top: -15px;
`

export const SecurityPinContainer = styled.div`
  padding-left: 150px;

  @media (max-width: ${tokens.mdMin}) {
    padding-left: 0;

    h6 {
      width: max-content;
    }
  }
`

export const SecurityPinInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  opacity: ${({ isDimmed }) => isDimmed && "0.5"};
`

export const PinInput = styled<PasswordProps>(Password)`
  margin-right: 20px;
  margin-top: 13px;
`

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ToggleHeader = styled.div`
  margin-left: 52px;
`

export const FileNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FileNameTextContainer = styled.div``

export const FileNameHeader = styled.div`
  padding: 8px 0px 0px 36px;
`
