import { JarvisAuthProvider } from "@jarvis/web-http"
import { DeviceShadowApiClient } from "@jarvis/web-stratus-client"
import {
  IconChevronRight,
  IconDocumentScanCloud,
  IconInfo,
  useToast,
} from "@veneer/core"
import React, { useCallback, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { DeviceProps } from "../../../mfe/types/device"
import { i18n } from "../../assets/I18n"
import Images from "../../assets/images"
import { ShortcutConfigurationResponse } from "../../data/schemas/deviceShadowApi"
import { ShortcutItem, SmartTask } from "../../data/schemas/shortcut"
import { templateEmptyTosField } from "../../data/template/templateEmptyTosField"
import { uiData } from "../../helpers/dataCollection"
import { resolveDeviceShadowStatus } from "../../helpers/resolveDeviceShadowStatus"
import useAccountMgtApi from "../../hooks/api/useAccountMgtApi"
import useDeviceShadow from "../../hooks/api/useDeviceShadow"
import useTenantApi from "../../hooks/api/useTenantApi"
import usePolling from "../../hooks/helpers/usePolling"
import DialogModal from "../DialogModal"
import ErrorWidget from "../ErrorWidget"
import Loader from "../Loader"
import LoaderScanDestinations from "../LoaderScanDestinations"
import ToggleScanDestinations from "../ToggleScanDestinations"
import {
  Button,
  Container,
  DestinationsContainer,
  ItemDescription,
  ItemInformation,
  ItemTitle,
  ScanToCloudIcon,
  ScanToCloudIconDisabled,
  ScanToDestinationContainer,
  ScanToDestinationContent,
  ScanToDestinationTitle,
  ScanToEmailIcon,
  ScanToEmailIconDisabled,
  TitleAndDescriptionContainer,
} from "./styles"

type SolutionItemProps = {
  stack: number
  authProvider: JarvisAuthProvider
  orgSelector: { getOrgTenantId: () => string }
  printer: DeviceProps
  sendUiEvent: { (uiDataObj: object): void }
  onFinish?: ({ isSupported }: { isSupported: boolean }) => void
  organizationName: string
}

const SolutionItem = ({
  stack,
  authProvider,
  orgSelector,
  printer,
  sendUiEvent,
  onFinish,
  organizationName,
}: SolutionItemProps) => {
  const history = useHistory()
  const { base } = useParams()
  const { addToast } = useToast()

  const cloudId = printer.deviceId
  const deviceUuid = printer.identity.deviceUuid

  const {
    getDeviceSupportStatus,
    createDeviceSession,
    createShortcut,
    deleteShortcut,
    getDeviceShortcuts,
    shortcutsCount,
    deviceShortcuts,
    getShortcuts,
    isLoading,
    setLoading,
  } = useTenantApi(stack, authProvider)

  const { getEntitlementStatus } = useAccountMgtApi(
    orgSelector.getOrgTenantId(),
    stack,
    authProvider
  )

  const {
    isLoadingShortcutConfiguration,
    enableScanDestinationStatus,
    getScanDestinationStatus,
    enableScanDestinationConfiguration,
    setIsShadowEmpty,
    isShadowEmpty,
  } = useDeviceShadow(stack, authProvider)

  const [deviceShadowRetrySuccessful, setDeviceShadowRetrySuccessful] =
    useState(false)

  const [showErrorWidget, setShowErrorWidget] = useState(false)
  const [toggleClicked, setToggleClicked] = useState<boolean>(false)
  const [toggleState, setToggleState] = useState<boolean>(false)
  const [displaySolutionComponent, setDisplaySolutionComponent] = useState(true)

  const [showInfoModal, setInfoModal] = useState(false)

  const { isFinished, finalResult } = usePolling({
    timeout: 120,
    callBack: async function () {
      return await getScanDestinationStatus(printer?.deviceId)
    },
    intervalTime: 15,
  })

  const filterShortcutEmailTemplate = (shortcuts: ShortcutItem[]) => {
    return shortcuts.filter(
      (shortcut: {
        id?: string
        category: string | string[]
        smartTask: SmartTask
      }) =>
        shortcut.category.includes("email") &&
        shortcut.smartTask.smartTaskConfig.email.tos.length === 0
    )
  }

  const setUpToggleScanDestinations = useCallback(async () => {
    setToggleState(!toggleState)
    await getDeviceShortcuts(deviceUuid)

    if (!toggleState) {
      await createDeviceSession(deviceUuid)

      const shortcutTemplates =
        deviceShortcuts.length !== 0
          ? filterShortcutEmailTemplate(deviceShortcuts)
          : []

      if (shortcutTemplates.length > 1) {
        deleteShortcut(shortcutTemplates[0].id)
      } else if (shortcutTemplates.length === 0) {
        try {
          await createShortcut(
            deviceUuid,
            templateEmptyTosField(organizationName) as ShortcutItem
          )
          sendUiEvent(uiData.BasicSettings.createScanToEmailTemplate)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error, "Template already exists")
        }
      }
    }

    !toggleState
      ? sendUiEvent(uiData.BasicSettings.enableScanDestToggle)
      : sendUiEvent(uiData.BasicSettings.disableScanDestToggle)

    const cloudShortcuts = "true" ? !toggleState : "false"

    await enableScanDestinationConfiguration(
      cloudId,
      15000,
      cloudShortcuts.toString()
    )
  }, [
    cloudId,
    createDeviceSession,
    createShortcut,
    deleteShortcut,
    deviceShortcuts,
    deviceUuid,
    enableScanDestinationConfiguration,
    getDeviceShortcuts,
    organizationName,
    toggleState,
    sendUiEvent,
  ])

  const scanDestinationHandler = async () => {
    try {
      setToggleClicked(true)
      await setUpToggleScanDestinations()
      setToggleClicked(false)
    } catch (error) {
      addToast({
        id: `enable-smb-scan-destinations-error`,
        type: "negative",
        text: i18n.assetsProvider.getText(
          "pages.ScanDestinations.toasts.enableScanDestinationsError"
        ),
      })
      setToggleClicked(false)
      setToggleState(toggleState)
    }
  }

  const handleSetupButtonClick = (type: "email" | "cloud") => {
    sendUiEvent(uiData.BasicSettings.screenBasicSettings)
    sendUiEvent({
      ...uiData.BasicSettings.btnSetUp,
      controlName:
        type === "cloud" ? "ScanToCloudSetUpButton" : "ScanToEmailSetUpButton",
    })
    createDeviceSession(deviceUuid)
    if (
      deviceShadowRetrySuccessful ||
      (enableScanDestinationStatus && enableScanDestinationStatus.isEnabled)
    ) {
      history.push(`${base}/${cloudId}/scan-destinations/${type}/add`)
    }
    type === "cloud"
      ? sendUiEvent(uiData.CreationForm.screenScanCloud)
      : sendUiEvent(uiData.CreationForm.screenScanEmail)
  }

  const handleManageButtonClick = (type: "email" | "cloud") => {
    sendUiEvent(uiData.BasicSettings.screenBasicSettings)
    sendUiEvent({
      ...uiData.BasicSettings.btnSetUp,
      controlName:
        type === "cloud"
          ? "ScanToCloudManageButton"
          : "ScanToEmailManageButton",
    })

    history.push(`${base}/${cloudId}/scan-destinations?tab=${type}`)
  }

  const fetchData = async () => {
    try {
      const { isSupported } = await getDeviceSupportStatus(deviceUuid)

      if (onFinish) {
        onFinish({ isSupported })
      }

      if (isSupported) {
        await getEntitlementStatus()
        await getDeviceShortcuts(deviceUuid)
        setShowErrorWidget(false)
        const client = new DeviceShadowApiClient(stack, authProvider)
        const { data } = await client.getShortcutConfiguration({ cloudId })
        const result = data as ShortcutConfigurationResponse
        const isToggleEnabled = resolveDeviceShadowStatus(result)
        await handleInitialToggle()
        setToggleState(isToggleEnabled)
        setDisplaySolutionComponent(true)
      } else {
        setDisplaySolutionComponent(false)
      }
    } catch (error) {
      if (error.message === "Error handling template") {
        setToggleState(false)
        return
      }
      setShowErrorWidget(true)
    }
  }

  const handleScanToEmailTemplate = useCallback(async () => {
    const client = new DeviceShadowApiClient(stack, authProvider)
    const { data } = await client.getShortcutConfiguration({ cloudId })
    const result = data as ShortcutConfigurationResponse
    const isToggleEnabled = resolveDeviceShadowStatus(result)
    if (isToggleEnabled) {
      await createDeviceSession(deviceUuid)
      const shortcuts = (await getShortcuts(deviceUuid)) as ShortcutItem[]
      const shortcutTemplates =
        shortcuts.length !== 0 ? filterShortcutEmailTemplate(shortcuts) : []

      if (shortcutTemplates.length > 1) {
        deleteShortcut(shortcutTemplates[0].id)
      } else if (shortcutTemplates.length === 0) {
        try {
          await createShortcut(
            deviceUuid,
            templateEmptyTosField(organizationName) as ShortcutItem
          )
          sendUiEvent(uiData.BasicSettings.createScanToEmailTemplate)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error, "Template already exists")
        }
      }
    }
  }, [
    authProvider,
    cloudId,
    createDeviceSession,
    createShortcut,
    deleteShortcut,
    deviceUuid,
    getShortcuts,
    organizationName,
    stack,
    sendUiEvent,
  ])

  const handleInitialToggle = async () => {
    try {
      await handleScanToEmailTemplate()
    } catch (error) {
      throw new Error("Error handling template")
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceUuid, cloudId, getDeviceSupportStatus])

  const handleIconClick = () => {
    setInfoModal(true)
  }

  const handleOnRetry = async () => {
    setShowErrorWidget(false)
    setLoading(true)
    setIsShadowEmpty(false)
    try {
      const client = new DeviceShadowApiClient(stack, authProvider)
      const { data } = await client.getShortcutConfiguration({ cloudId })
      const result = data as ShortcutConfigurationResponse
      const isToggleEnabled = resolveDeviceShadowStatus(result)
      setToggleState(isToggleEnabled)
      if (isToggleEnabled) await handleInitialToggle()
      const reported = result.state.reported
      if (reported && !reported?.cdmData?.cloudShortcuts) {
        setIsShadowEmpty(true)
        setLoading(false)
        setShowErrorWidget(true)
        return
      }
      if (reported?.cdmData?.cloudShortcuts.toLowerCase() === "true") {
        setDeviceShadowRetrySuccessful(true)
        setShowErrorWidget(false)
        setLoading(false)
        setDisplaySolutionComponent(true)
      }
      if (reported?.cdmData?.cloudShortcuts.toLowerCase() === "false") {
        setShowErrorWidget(false)
        setLoading(false)
        setDisplaySolutionComponent(true)
      }
    } catch (error) {
      setLoading(false)
      setShowErrorWidget(true)
    }
  }

  useEffect(() => {
    const hasEntitlement = sessionStorage.getItem("entitlement")
    if (
      isFinished &&
      finalResult &&
      hasEntitlement &&
      enableScanDestinationStatus &&
      !showErrorWidget
    ) {
      setDisplaySolutionComponent(true)
    }

    if (isFinished && !finalResult) {
      setShowErrorWidget(true)
    }
    if (isShadowEmpty) {
      setShowErrorWidget(true)
    }
    return () => {
      handleInitialToggle()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!displaySolutionComponent && !showErrorWidget && isFinished) {
    return null
  }

  const emailShortcuts = deviceShortcuts.filter((shortcut: ShortcutItem) =>
    shortcut.category.includes("email") &&
    shortcut.smartTask.smartTaskConfig.email.tos.length !== 0
      ? true
      : false
  )

  return (
    <>
      <Container id="content-container">
        <IconDocumentScanCloud size={40} />
        <ItemInformation id="item-information">
          <ItemTitle
            className="subtitle-regular"
            tag={i18n.assetsProvider.getText("pages.SolutionItem.tag")}
          >
            {i18n.assetsProvider.getText(
              "pages.SolutionItem.scanDestinationsHeader"
            )}
          </ItemTitle>
          <IconInfo
            data-testid="information-icon-sd"
            size={25}
            color="colorHpBlue6"
            cursor={"pointer"}
            onClick={() => {
              handleIconClick()
            }}
          />
        </ItemInformation>
        {(isLoading && !isLoadingShortcutConfiguration && !toggleClicked) ||
        !isFinished ? (
          <Loader />
        ) : (
          !showErrorWidget && (
            <ToggleScanDestinations
              onChange={scanDestinationHandler}
              disabled={toggleClicked}
              on={toggleState}
            />
          )
        )}
      </Container>
      <DialogModal
        show={showInfoModal}
        title={i18n.assetsProvider.getText(
          "pages.SolutionItem.scanDestinationsHeader"
        )}
        upDescription={i18n.assetsProvider.getText(
          "pages.ScanDestinations.dialogues.informationModalDescription1"
        )}
        description={i18n.assetsProvider.getText(
          "pages.ScanDestinations.dialogues.informationModalDescription2"
        )}
        onClose={() => setInfoModal(false)}
        closeButton={true}
        titleIcon={<IconInfo focusable size={40} color="colorHpBlue6" />}
        notice={{
          title: i18n.assetsProvider.getText(
            "pages.ScanDestinations.dialogues.informationModalNoticeTitle"
          ),
          description: i18n.assetsProvider.getText(
            "pages.ScanDestinations.dialogues.informationModalNoticeDescription"
          ),
          icon: <IconInfo filled={true} size={22} color="colorGray5" />,
        }}
        isInfoModal={true}
      />
      {isFinished && showErrorWidget ? (
        <ErrorWidget
          onRetry={() => {
            handleOnRetry()
          }}
        />
      ) : (
        ((!isLoading && isLoadingShortcutConfiguration) || isFinished) && (
          <>
            <DestinationsContainer id="destinations-container">
              <LoaderScanDestinations
                isLoading={toggleClicked}
                toggleEnabled={toggleState}
              />
              <ScanToDestinationContainer id="scan-to-destination-email-container">
                <ScanToDestinationContent>
                  {toggleState ? (
                    <ScanToEmailIcon>
                      <img
                        src={Images.scanToEmail}
                        alt="Scan to email icon"
                        className="solutionItemVector"
                      />
                    </ScanToEmailIcon>
                  ) : (
                    <ScanToEmailIconDisabled>
                      <img
                        src={Images.scanToEmailDisabled}
                        alt="Scan to email icon disabled"
                        className="solutionItemVector"
                      />
                    </ScanToEmailIconDisabled>
                  )}

                  <TitleAndDescriptionContainer>
                    <ScanToDestinationTitle
                      id="scan-to-destination-email-title"
                      disabled={!toggleState}
                      className="label"
                    >
                      {i18n.assetsProvider.getText("common.scanToEmail", {
                        shortcutCount:
                          emailShortcuts.length !== 0
                            ? `(${emailShortcuts.length.toString()})`
                            : "",
                      })}
                    </ScanToDestinationTitle>
                    <ItemDescription
                      id="item-description"
                      className="caption"
                      disabled={!toggleState}
                    >
                      {i18n.assetsProvider.getText(
                        "pages.ScanDestinations.scanToEmail.scanToEmailDescription"
                      )}
                    </ItemDescription>
                  </TitleAndDescriptionContainer>
                </ScanToDestinationContent>
                <Button
                  id="email-button"
                  appearance={emailShortcuts.length !== 0 ? "ghost" : "primary"}
                  disabled={!toggleState}
                  loading={
                    isLoading &&
                    !isLoadingShortcutConfiguration &&
                    !toggleClicked
                  }
                  onClick={() => {
                    emailShortcuts.length !== 0
                      ? handleManageButtonClick("email")
                      : handleSetupButtonClick("email")
                  }}
                  trailingIcon={
                    emailShortcuts.length !== 0 ? (
                      <IconChevronRight />
                    ) : undefined
                  }
                >
                  {emailShortcuts.length !== 0
                    ? i18n.assetsProvider.getText("common.manage")
                    : i18n.assetsProvider.getText("common.setUp")}
                </Button>
              </ScanToDestinationContainer>
              <ScanToDestinationContainer id="scan-to-destination-cloud-container">
                <ScanToDestinationContent>
                  {toggleState ? (
                    <ScanToCloudIcon>
                      <img
                        src={Images.scanToCloud}
                        alt="Scan to cloud icon"
                        className="solutionItemVector"
                      />
                    </ScanToCloudIcon>
                  ) : (
                    <ScanToCloudIconDisabled>
                      <img
                        src={Images.scanToCloudDisabled}
                        alt="Scan to cloud icon disabled"
                        className="solutionItemVector"
                      />
                    </ScanToCloudIconDisabled>
                  )}

                  <TitleAndDescriptionContainer>
                    <ScanToDestinationTitle
                      id="scan-to-destination-cloud-title"
                      className="label"
                      disabled={!toggleState}
                    >
                      {i18n.assetsProvider.getText("common.scanToCloud", {
                        shortcutCount: !shortcutsCount?.save
                          ? ""
                          : `(${shortcutsCount?.save})`,
                      })}
                    </ScanToDestinationTitle>
                    <ItemDescription
                      id="item-description"
                      className="caption"
                      disabled={!toggleState}
                    >
                      {i18n.assetsProvider.getText(
                        "pages.ScanDestinations.scanAndSave.scanToCloudDescription"
                      )}
                    </ItemDescription>
                  </TitleAndDescriptionContainer>
                </ScanToDestinationContent>
                <Button
                  id="cloud-button"
                  disabled={!toggleState}
                  appearance={shortcutsCount?.save ? "ghost" : "primary"}
                  loading={
                    isLoading &&
                    !isLoadingShortcutConfiguration &&
                    !toggleClicked
                  }
                  onClick={() => {
                    shortcutsCount?.save
                      ? handleManageButtonClick("cloud")
                      : handleSetupButtonClick("cloud")
                  }}
                  trailingIcon={
                    shortcutsCount?.save ? <IconChevronRight /> : undefined
                  }
                >
                  {shortcutsCount?.save
                    ? i18n.assetsProvider.getText("common.manage")
                    : i18n.assetsProvider.getText("common.setUp")}
                </Button>
              </ScanToDestinationContainer>
            </DestinationsContainer>
          </>
        )
      )}
    </>
  )
}

export default SolutionItem
