import { Checkbox, Toggle } from "@veneer/core"
import { useFlags } from "launchdarkly-react-client-sdk"
import React from "react"
import { i18n } from "../../assets/I18n"
import {
  FileTypeOptions,
  ocrLanguageConfigOptions,
  ocrLanguageConfigOptionsList,
  ProFileTypeOptions,
  RegularFileTypeOptions,
} from "../../data/enum/fileType"
import {
  ContentContainer,
  ContentLanguageSelect,
  FileNameContainer,
  FileNameHeader,
  FileNameTextContainer,
  FileNameTitle,
  FileTypeContainer,
  FileTypeSelect,
  FileTypeTitle,
  MainContainer,
  PinInput,
  PinTitle,
  SecurityPinContainer,
  SecurityPinInputContainer,
  SettingsTitle,
  ToggleContainer,
  ToggleHeader,
} from "./styles"

type FileTypeFormSectionProps = {
  selectedFileType: string
  selectedFileHelperText: string
  selectedContentLanguage: string
  onSelectFileType: { (value: FileTypeOptions): void }
  onSelectContentLanguage: { (value: ocrLanguageConfigOptions): void }
  entitled: boolean
  isSecurityPinToggleActive: boolean
  onChangeSecurityPinAdded: { (value: boolean): void }
  isFileNameAdded: boolean
  onChangeFileNameAdded: { (value: boolean): void }
  securityPin: string
  pinError: string
  onChangeSecurityPin: { (value: string): void }
  hasSecurityPinErrors: boolean
  securityPinHelperText?: string
  securityPinConfirmation: string
  onChangeSecurityPinConfirmation: { (value: string): void }
  onPinBlur: { (): void }
  securityPinMaxLength: number
}

const FileTypeFormSection = (props: FileTypeFormSectionProps) => {
  const { smbPinProtected } = useFlags()

  const defaultButtonList: RegularFileTypeOptions[] = [
    {
      value: "standardpdf",
      label: i18n.assetsProvider.getText(
        "pages.ShortcutCreation.settingsSection.typeOptions.standardPDF"
      ),
    },
    {
      value: "jpeg",
      label: i18n.assetsProvider.getText(
        "pages.ShortcutCreation.settingsSection.typeOptions.image"
      ),
    },
  ]

  const complementaryButtonList: ProFileTypeOptions[] = [
    {
      value: "searchablepdf",
      label: i18n.assetsProvider.getText(
        "pages.ShortcutCreation.settingsSection.typeOptions.searchablePDF"
      ),
      description: i18n.assetsProvider.getText(
        "pages.ShortcutCreation.fileTypeSection.typeOptions.searchablePDFDescription"
      ),
    },
    {
      value: "docx",
      label: i18n.assetsProvider.getText(
        "pages.ShortcutCreation.settingsSection.typeOptions.wordDocument"
      ),
      description: i18n.assetsProvider.getText(
        "pages.ShortcutCreation.fileTypeSection.typeOptions.wordDocumentDescription"
      ),
    },
    {
      value: "txt",
      label: i18n.assetsProvider.getText(
        "pages.ShortcutCreation.settingsSection.typeOptions.plainText"
      ),
      description: i18n.assetsProvider.getText(
        "pages.ShortcutCreation.fileTypeSection.typeOptions.plainTextDescription"
      ),
    },
  ]

  const CardContent = () => (
    <>
      <SettingsTitle>
        {i18n.assetsProvider.getText(
          "pages.ShortcutCreation.settingsSection.title"
        )}
      </SettingsTitle>
      <ContentContainer id="file-type-form-container">
        <FileTypeContainer id="file-type-content-container">
          <FileTypeTitle>
            {i18n.assetsProvider.getText(
              "pages.ShortcutCreation.settingsSection.typeTitle"
            )}
          </FileTypeTitle>
          <FileTypeSelect
            id="file-type-select"
            options={
              props.entitled
                ? [...defaultButtonList, ...complementaryButtonList]
                : defaultButtonList
            }
            label={i18n.assetsProvider.getText(
              "pages.ShortcutCreation.settingsSection.typeTitle"
            )}
            helperText={props.selectedFileHelperText}
            helperTextVisibility={
              complementaryButtonList.some(
                (e) => e.value === props.selectedFileType
              )
                ? "visible"
                : "auto"
            }
            value={[props.selectedFileType]}
            clearIcon={false}
            onChange={props.onSelectFileType}
            visibleOptions={5}
            required
          />
          {complementaryButtonList.some(
            (e) => e.value === props.selectedFileType
          ) && (
            <>
              <ContentLanguageSelect
                id="content-language-select"
                options={ocrLanguageConfigOptionsList}
                label={i18n.assetsProvider.getText(
                  "pages.ShortcutCreation.settingsSection.contentLanguage"
                )}
                value={[props.selectedContentLanguage]}
                clearIcon={false}
                onChange={props.onSelectContentLanguage}
                visibleOptions={5}
                required
              />
              <FileNameContainer>
                <FileNameTitle>
                  {i18n.assetsProvider.getText(
                    "pages.ShortcutCreation.settingsSection.fileNameTitle"
                  )}
                </FileNameTitle>
                <Checkbox
                  label={i18n.assetsProvider.getText(
                    "pages.ShortcutCreation.settingsSection.smartFileNameLabel"
                  )}
                  checked={props.isFileNameAdded}
                  onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                    props.onChangeFileNameAdded(value.target.checked)
                  }}
                />

                <FileNameTextContainer>
                  <FileNameHeader className="label">
                    <p className="caption">
                      {i18n.assetsProvider.getText(
                        "pages.ShortcutCreation.settingsSection.smartFileNameDescriptionNew"
                      )}
                    </p>
                  </FileNameHeader>
                </FileNameTextContainer>
              </FileNameContainer>
            </>
          )}
        </FileTypeContainer>
        {smbPinProtected && props.entitled && (
          <SecurityPinContainer id="security-pin-container">
            <PinTitle>
              {i18n.assetsProvider.getText(
                "pages.ShortcutCreation.settingsSection.securityPin.label"
              )}
            </PinTitle>
            <Toggle
              id="custom-toggle"
              label={i18n.assetsProvider.getText(
                "pages.ShortcutCreation.settingsSection.securityPin.toggleLabel"
              )}
              on={props.isSecurityPinToggleActive}
              onChange={props.onChangeSecurityPinAdded}
            />
            <ToggleContainer>
              <ToggleHeader>
                <p className="caption">
                  {i18n.assetsProvider.getText(
                    "pages.ShortcutCreation.settingsSection.securityPin.toggleDescriptionNew"
                  )}
                </p>
              </ToggleHeader>
            </ToggleContainer>
            {props.isSecurityPinToggleActive && (
              <SecurityPinInputContainer id="security-pin-input-container">
                <PinInput
                  id="pin-input"
                  type="number"
                  value={props.securityPin}
                  maxLength={props.securityPinMaxLength}
                  placeholder={i18n.assetsProvider.getText(
                    "pages.ShortcutCreation.settingsSection.securityPin.pinPlaceholder"
                  )}
                  helperText={i18n.assetsProvider.getText(
                    "pages.ShortcutCreation.settingsSection.securityPin.characterCountEightDigitsDisclaimer"
                  )}
                  onChange={props.onChangeSecurityPin}
                />
                <PinInput
                  id="pin-input-confirmation"
                  type="number"
                  value={props.securityPinConfirmation}
                  maxLength={props.securityPinMaxLength}
                  disabled={props.securityPin.length < 8}
                  placeholder={i18n.assetsProvider.getText(
                    "pages.ShortcutCreation.settingsSection.securityPin.confirmationPlaceholder"
                  )}
                  error={props.hasSecurityPinErrors}
                  helperText={props.pinError}
                  onChange={props.onChangeSecurityPinConfirmation}
                />
              </SecurityPinInputContainer>
            )}
          </SecurityPinContainer>
        )}
      </ContentContainer>
    </>
  )

  return (
    <MainContainer id="file-type-form-main-container" content={CardContent()} />
  )
}

export default FileTypeFormSection
