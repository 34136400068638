import { Card, TextArea } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const MainContainer = styled(Card)`
  display: grid;
  grid-template-rows: auto auto;
`

export const EmailTitle = styled.h6`
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 23px;
  @media (max-width: ${tokens.lgMin}) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  @media (max-width: ${tokens.smMin}) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`

export const EmailContentContainer = styled.div`
  padding-top: 23px;
  padding-bottom: 23px;
  margin-left: 23px;
  margin-right: 23px;
  border-top: 1px solid ${tokens.colorGray2};
  div {
    &:nth-child(1) {
      padding-bottom: 16px;
    }
  }
`

export const ReceiverTextArea = styled(TextArea)`
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  padding: 23px;
`

export const SubjectTextArea = styled(TextArea)`
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  textarea {
    white-space: nowrap !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
  }
`

export const MessageTextArea = styled(TextArea)`
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
`

export const MultipleRecipientsDescription = styled.p`
  color: ${tokens.colorGray10};
  margin-bottom: -5px;
  padding: 20px 23px 0 23px;
  border-top: 1px solid ${tokens.colorGray2};
`
