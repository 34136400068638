import { CustomImage, Tabs, TabsId } from "@veneer/core"
import React, { useCallback, useEffect, useState } from "react"
import { i18n } from "../../assets/I18n"
import CustomSearch from "../../components/CustomSearch"
import ProTooltip from "../../components/ProTooltip"
import { providers, ProvidersOptions } from "../../data/enum/providers"
import { CapabilityItem, ConnectorData } from "../../data/schemas/connector"
import ErrorWidget from "../ErrorWidget"
import Loader from "../Loader"
import {
  AlignProTooltip,
  AuthAccFrame,
  AuthNewAccFrame,
  ChevronContainer,
  ConnectorItemContainer,
  ConnectorItemHeader,
  ConnectorItemImage,
  ConnectorItemName,
  ConnectorItemType,
  CustomDescription,
  CustomErrorText,
  ErrorWidgetContainer,
  ItemDivider,
  ParentBox,
  ProviderItemContainer,
  ProviderItemHeader,
  ProviderItemImage,
  ProviderItemName,
  StyledIconChevronRight,
  StyledModal,
  TopContentContainer,
} from "./styles"

type DialogModalProps = {
  show: boolean
  addProviderButtonTitle: string
  providerListTitle: string
  modalTitle: string
  cloudDestinationType: string
  authenticatedConnectorItems: CapabilityItem[]
  providersAlreadySelectedList: ConnectorData[]
  onSelectConnectorItem?: { (provider: CapabilityItem): void }
  onAddNew?: { (providerName: String): void }
  onClose?: { (): void }
  providersList: ProvidersOptions[]
  onRetry: { (): void }
  isLoading: boolean
}

type ConnectorItemProps = {
  connectorName: string
  connectorType: string
  onSelect: { (providerName: String): void }
}

type ProviderItemProps = {
  name: string
  type: string
  onSelect: { (providerName: String): void }
}

const ConnectorItem = ({
  connectorName,
  connectorType,
  onSelect,
}: ConnectorItemProps) => {
  return (
    <>
      <ConnectorItemContainer onClick={onSelect}>
        <ConnectorItemImage>
          <CustomImage
            src={providers[`auth${connectorType}`].icon.toString()}
            size={48}
            alt={connectorType}
          />
        </ConnectorItemImage>
        <ConnectorItemHeader>
          <ConnectorItemName className="label">
            {connectorName}
          </ConnectorItemName>
          <ConnectorItemType className="caption-small">
            {providers[`auth${connectorType}`].name}
          </ConnectorItemType>
        </ConnectorItemHeader>
        <AlignProTooltip>
          <ProTooltip type={providers[`auth${connectorType}`].name} />
        </AlignProTooltip>
        <ChevronContainer>
          <StyledIconChevronRight color="colorGray4" />
        </ChevronContainer>
      </ConnectorItemContainer>
      <ItemDivider />
    </>
  )
}

const ProviderItem = ({ name, type, onSelect }: ProviderItemProps) => {
  return (
    <ProviderItemContainer onClick={onSelect}>
      <ProviderItemImage>
        <CustomImage
          src={providers[name].icon.toString()}
          size={52}
          alt={type}
        />
      </ProviderItemImage>
      <ProviderItemHeader>
        <ProviderItemName className="subtitle-regular">{type}</ProviderItemName>
        <ProTooltip type={type} />
      </ProviderItemHeader>
      <ChevronContainer>
        <StyledIconChevronRight color="colorGray4" />
      </ChevronContainer>
    </ProviderItemContainer>
  )
}

const CloudDestinationModal = ({
  show,
  providerListTitle,
  modalTitle,
  authenticatedConnectorItems,
  onSelectConnectorItem,
  onClose,
  providersList,
  onAddNew,
  onRetry,
  isLoading,
  providersAlreadySelectedList,
}: DialogModalProps) => {
  const [selectedTabIdDefault, setSelectedTabIdDefault] = useState<TabsId>(0)
  const [tableData, setTableData] = useState([])
  const [searchValue, setSearchValue] = useState<string>("")

  const handleChange = (value?: string) => {
    setSearchValue(value)
  }

  const handleClear = useCallback(() => {
    setTableData(authenticatedConnectorItems)
  }, [authenticatedConnectorItems])

  const handleSubmit = useCallback(() => {
    if (!searchValue) {
      setTableData(authenticatedConnectorItems)
      return
    }

    setTableData(
      authenticatedConnectorItems.filter((item: CapabilityItem) => {
        return (
          item.connectorName
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          providers[item.type].name
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      })
    )
  }, [authenticatedConnectorItems, searchValue])

  useEffect(() => {
    setTableData(authenticatedConnectorItems)
  }, [authenticatedConnectorItems])

  const loadConnectorItems = () => {
    if (isLoading) {
      return (
        <ErrorWidgetContainer>
          <Loader />
        </ErrorWidgetContainer>
      )
    }

    if (tableData === undefined) {
      return (
        <ErrorWidgetContainer>
          <ErrorWidget
            onRetry={() => {
              onRetry()
            }}
          />
        </ErrorWidgetContainer>
      )
    }

    if (tableData?.length === 0) {
      return (
        <CustomErrorText className="label">
          {i18n.assetsProvider.getText(
            "pages.ShortcutCreation.cloudSection.tableNoDestinations"
          )}
        </CustomErrorText>
      )
    }

    return (
      <>
        {tableData
          ?.filter(
            (provider: CapabilityItem) =>
              !providersAlreadySelectedList.find(
                (fetchedProvider) =>
                  fetchedProvider.connector.connectorId === provider.connectorId
              )
          )
          ?.map((item) => {
            return (
              <ConnectorItem
                key={`${item.connectorName}-${item.type}-connector`}
                connectorName={item.connectorName}
                connectorType={item.type}
                onSelect={() => {
                  onSelectConnectorItem(item)
                  onClose()
                }}
              />
            )
          })}
      </>
    )
  }

  return (
    <StyledModal
      id="destination-modal-container"
      onClose={onClose}
      closeButton
      show={show}
      align="start"
      title={modalTitle}
    >
      <CustomDescription className="body">
        {providerListTitle}
      </CustomDescription>
      <Tabs
        controlId="default"
        mode="extended"
        onChangeTab={(tab: TabsId) => {
          setSelectedTabIdDefault(tab)
        }}
        selectedTabId={selectedTabIdDefault}
        type="online"
        tabs={[
          {
            id: 0,
            label: i18n.assetsProvider.getText(
              "pages.ShortcutCreation.cloudSection.authenticateCloudDestinationNewAccount"
            ),
            content: (
              <ParentBox>
                <AuthNewAccFrame>
                  {providersList.map(({ value, label }) => {
                    return (
                      <ProviderItem
                        key={`${value}`}
                        name={value}
                        type={label}
                        onSelect={() => {
                          onAddNew(value)
                        }}
                      />
                    )
                  })}
                </AuthNewAccFrame>
              </ParentBox>
            ),
          },
          {
            id: 1,
            label:
              authenticatedConnectorItems === undefined || isLoading
                ? i18n.assetsProvider.getText(
                    "pages.ShortcutCreation.cloudSection.authenticatedCloudDestinationSubtitle"
                  )
                : `${i18n.assetsProvider.getText(
                    "pages.ShortcutCreation.cloudSection.authenticatedCloudDestinationSubtitle"
                  )} (${
                    authenticatedConnectorItems?.length -
                    providersAlreadySelectedList.length
                  })`,
            content: (
              <>
                {authenticatedConnectorItems?.length >= 5 && (
                  <TopContentContainer id="destination-modal-top-content-container">
                    <CustomSearch
                      searchLabel={i18n.assetsProvider.getText(
                        "common.searchAuthenticatedAccounts"
                      )}
                      onChange={handleChange}
                      onClear={handleClear}
                      onSubmitHandler={handleSubmit}
                      isFullBar={true}
                    />
                  </TopContentContainer>
                )}
                <AuthAccFrame>{loadConnectorItems()}</AuthAccFrame>
              </>
            ),
          },
        ]}
      />
    </StyledModal>
  )
}

export default CloudDestinationModal
