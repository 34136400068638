import { JarvisAuthProvider } from "@jarvis/web-http"
import {
  Button,
  CustomImage,
  IconEnvelope,
  IconLock,
  IconPencil,
  IconPlus,
  IconTrash,
  IconWarning,
  SortTypes,
  Table,
  Tooltip,
  useToast,
} from "@veneer/core"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { i18n } from "../../assets/I18n"
import { providers } from "../../data/enum/providers"
import { CapabilityItem, Site } from "../../data/schemas/connector"
import { FolderInfoItem, FolderNameResponse } from "../../data/schemas/folders"
import { RepositoryConfig, ShortcutItem } from "../../data/schemas/shortcut"
import { uiData } from "../../helpers/dataCollection"
import { fileTypeHandler } from "../../helpers/fileTypeHelper"
import { resolveCapabilitiesId } from "../../helpers/resolveCapabilitiesId"
import { resolveFolderIds } from "../../helpers/resolveFolderIds"
import { sort, tableI18n } from "../../helpers/table"
import useTenantApi from "../../hooks/api/useTenantApi"
import CustomSearch from "../CustomSearch"
import DialogModal from "../DialogModal"
import MaxDestinationModal from "../MaxDestinationModal"
import QuickLookModal from "../QuickLookModal"
import {
  MainContainer,
  ScanDestinationsItemsCloudContainer,
  ShortcutActionsContainer,
  ShortcutDestinationItemContainer,
  ShortcutDestinationItemText,
  ShortcutName,
  StyledTitleIcon,
  StyledWarningContainer,
  TopContentContainer,
} from "./styles"

type ScanToDestinationProps = {
  stack: number
  authProvider: JarvisAuthProvider
  deviceUuid: string
  imgSrc: string
  title: string
  subtitle: string
  destinationType: string
  isLoading: boolean
  tableData: any
  onDeleteShortcut: { (value: boolean): void }
  shortcutDeletedState: boolean
  tab: number | string
  disabled: boolean
  sendUiEvent: { (uiDataObj: object): void }
  capabilities?: CapabilityItem[]
  shortcut?: ShortcutItem[]
  cloudId?: string
  folderNameItems?: FolderNameResponse
  siteItem?: Site[]
  totalDeviceShortcuts: number
}

type TableSort = {
  orderBy: string
  orderType: SortTypes
}

const ScanToDestination = (props: ScanToDestinationProps) => {
  const history = useHistory()
  const { base, cloudId } = useParams()
  const { deleteShortcut, isLoading: deletingShortcutLoading } = useTenantApi(
    props.stack,
    props.authProvider
  )

  const { addToast } = useToast()

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEmailQuickLookModal, setShowEmailQuickLookModal] = useState(false)
  const [showCloudQuickLookModal, setShowCloudQuickLookModal] = useState(false)
  const [showMaxDestinationModal, setShowMaxDestinationModal] = useState(false)
  const [currentShortcut, setCurrentShortcut] = useState<ShortcutItem>()
  const [searchValue, setSearchValue] = useState<string>("")

  let listOfCapabilitiesIds: string[] = []
  let listOfInvalidFolderIds: string[] = []
  let listOfFolderNameItems: FolderInfoItem[] = []
  let listOfSiteItems: Site[] = []

  const shortcutActions = (shortcut: ShortcutItem, disabled = false) => (
    <ShortcutActionsContainer id={`${shortcut.id}-action-container`}>
      <IconTrash
        color={disabled ? "colorGray4" : "colorHpBlue6"}
        size={26}
        // @ts-ignore https://github.azc.ext.hp.com/veneer/veneer/issues/3714
        onClick={() => {
          setCurrentShortcut(shortcut)
          setShowDeleteModal(true)
        }}
      />
      <IconPencil
        color={disabled ? "colorGray4" : "colorHpBlue6"}
        size={26}
        // @ts-ignore https://github.azc.ext.hp.com/veneer/veneer/issues/3714
        onClick={() => {
          history.push(
            `${base}/${cloudId}/scan-destinations/${shortcut.id}/${props.destinationType}/edit`,
            { state: { cloudId } }
          )
        }}
      />
    </ShortcutActionsContainer>
  )

  const shortcutDestinations = (
    shortcut: ShortcutItem,
    tab: number | string,
    disabled: boolean,
    listOfCapabilitiesIds: string[],
    listOfInvalidFolderIds: string[]
  ) => (
    <div>
      {tab === 0
        ? shortcutDestinationsEmail(shortcut, disabled)
        : shortcutDestinationsCloud(
            shortcut,
            disabled,
            listOfCapabilitiesIds,
            listOfInvalidFolderIds
          )}
    </div>
  )

  const shortcutDestinationsEmail = (
    shortcut: ShortcutItem,
    disabled = false
  ) => (
    <ShortcutDestinationItemContainer
      id={`${shortcut?.id}-item-container`}
      onClick={() => {
        setCurrentShortcut(shortcut)
        setShowEmailQuickLookModal(true)
      }}
    >
      <IconEnvelope
        color={disabled ? "colorGray4" : "colorPurple4"}
        size={24}
        filled
      />
      <ShortcutDestinationItemText disabled={disabled}>
        ({shortcut?.smartTask?.smartTaskConfig?.email.tos.length})
      </ShortcutDestinationItemText>
    </ShortcutDestinationItemContainer>
  )

  const shortcutDestinationsCloud = (
    shortcut: ShortcutItem,
    disabled = false,
    listOfCapabilitiesIds: string[],
    listOfInvalidFolderIds: string[]
  ) => (
    <ScanDestinationsItemsCloudContainer id="scan-destinations-cloud-container">
      {shortcut?.smartTask?.smartTaskConfig?.repositories.map(
        (item: RepositoryConfig) => (
          <ShortcutDestinationItemContainer
            key={`${item.connectorId}-item-container`}
            id={`${shortcut?.id}-item-container`}
            onClick={() => {
              setCurrentShortcut(shortcut)
              setShowCloudQuickLookModal(true)
            }}
          >
            <StyledWarningContainer>
              {listOfCapabilitiesIds.length &&
              !listOfCapabilitiesIds.includes(item.connectorId) ? (
                <Tooltip
                  arrow
                  content={i18n.assetsProvider.getText(
                    "pages.ScanDestinations.tooltip.invalidDestinationWarning"
                  )}
                  placement="bottom-start"
                  portal
                >
                  <IconWarning color="colorRed6" filled />
                </Tooltip>
              ) : listOfInvalidFolderIds.length &&
                item.folderList &&
                item.folderList.some((element) =>
                  listOfInvalidFolderIds.includes(element.folderId)
                ) ? (
                <Tooltip
                  arrow
                  content={i18n.assetsProvider.getText(
                    "pages.ScanDestinations.tooltip.folderRemovedWarning"
                  )}
                  placement="bottom-start"
                  portal
                >
                  <IconWarning color="colorRed6" filled />
                </Tooltip>
              ) : null}
            </StyledWarningContainer>
            <CustomImage
              src={providers[item.type].icon.toString()}
              size={24}
              disabled={disabled}
              aria-label={`custom ${providers[item.type].name} image`}
            />
            <ShortcutDestinationItemText disabled={disabled}>
              ({item.destinations.length})
            </ShortcutDestinationItemText>
          </ShortcutDestinationItemContainer>
        )
      )}
    </ScanDestinationsItemsCloudContainer>
  )

  const createTableItems = (tableData: ShortcutItem[]) => {
    if (props.capabilities) {
      listOfCapabilitiesIds = resolveCapabilitiesId(props.capabilities)
    }

    if (props.folderNameItems) {
      listOfInvalidFolderIds = resolveFolderIds(props.folderNameItems)
    }

    if (props.folderNameItems?.folderInfoList !== undefined) {
      listOfFolderNameItems = props.folderNameItems.folderInfoList
    }

    if (props.siteItem !== undefined) {
      listOfSiteItems = props.siteItem
    }

    const tableDataItems = tableData.map((shortcut: ShortcutItem) => ({
      searchableName: shortcut?.smartTask?.jobName,
      searchableTos: shortcut?.smartTask?.smartTaskConfig?.email?.tos.map(
        (to: string) => to.toLocaleLowerCase()
      ),
      name: (
        <ShortcutName>
          {shortcut.pin ? (
            <IconLock filled customStyle={{ alignSelf: "center" }} />
          ) : null}{" "}
          {shortcut?.smartTask?.jobName}
        </ShortcutName>
      ),
      destinations: shortcutDestinations(
        shortcut,
        props.tab,
        props.disabled,
        listOfCapabilitiesIds,
        listOfInvalidFolderIds
      ),
      filetype: fileTypeHandler(shortcut),
      actions: shortcutActions(shortcut),
    }))
    return tableDataItems
  }

  const tableDataMemoized = useMemo(() => {
    return createTableItems(props.tableData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tab, props.tableData, listOfFolderNameItems, listOfSiteItems])

  const [filteredTableData, setFilteredTableData] = useState(tableDataMemoized)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(6)
  const [orderType, setOrderType] = useState("ascending")

  const handlePageChange = (page: any) => {
    setCurrentPage(page)
  }

  const handlePageSizeChange = (_: any, option: any) => {
    setPageSize(option.value)
  }

  const transformFunction = (value: any) => {
    return value?.searchableName.toLowerCase()
  }

  const sortedData = sort(filteredTableData as [], orderType, transformFunction)

  const pagedData = sortedData.slice(
    (currentPage - 1) * pageSize,
    (currentPage - 1) * pageSize + pageSize
  )

  const initialTableSort = useRef<TableSort>({
    orderBy: "name",
    orderType: "ascending",
  })

  const onPlusButtonClick = () => {
    if (props?.totalDeviceShortcuts >= 100) {
      setShowMaxDestinationModal(true)
      return
    }

    history.push(
      `${base}/${cloudId}/scan-destinations/${props.destinationType}/add`,
      {
        state: {
          deviceUuid: props.deviceUuid,
          cloudId,
        },
      }
    )

    props.sendUiEvent({
      ...uiData.ScanDestinationsTabs.screenScanDestTabs,
      screenMode:
        props.destinationType === "cloud" ? "CloudManage" : "EmailManage",
    })

    props.sendUiEvent({
      ...uiData.ScanDestinationsTabs.btnCreateNew,
      screenMode:
        props.destinationType === "cloud" ? "CloudManage" : "EmailManage",
    })

    props.destinationType === "cloud"
      ? props.sendUiEvent(uiData.CreationForm.screenScanCloud)
      : props.sendUiEvent(uiData.CreationForm.screenScanEmail)
  }

  const onDeleteButtonClick = async () => {
    try {
      await deleteShortcut(currentShortcut?.id)
      props.onDeleteShortcut(true)
      addToast({
        id: "smb-scan-destinations-delete-success",
        type: "positive",
        text: i18n.assetsProvider.getText(
          "pages.ScanDestinations.toasts.saveChangesSuccess"
        ),
      })
    } catch (error) {
      addToast({
        id: "smb-scan-destinations-delete-error",
        type: "negative",
        text: i18n.assetsProvider.getText(
          "pages.ScanDestinations.toasts.saveChangesError"
        ),
      })
    } finally {
      setShowDeleteModal(false)
      setCurrentShortcut(undefined)
    }
  }

  const handleChange = (value?: string) => {
    setSearchValue(value)
  }

  useEffect(() => {
    const { tab } = props
    if (!searchValue) {
      setFilteredTableData(tableDataMemoized)
      return
    }
    setFilteredTableData(
      tab === 0
        ? handleSearchEmailTab(searchValue, tableDataMemoized)
        : handleSearchCloudTab(searchValue, tableDataMemoized)
    )
    setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const handleClear = useCallback(() => {
    setFilteredTableData(tableDataMemoized)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableDataMemoized])

  const handleSubmit = useCallback(() => {
    const { tab } = props
    if (!searchValue) {
      setFilteredTableData(tableDataMemoized)
      return
    }
    setFilteredTableData(
      tab === 0
        ? handleSearchEmailTab(searchValue, tableDataMemoized)
        : handleSearchCloudTab(searchValue, tableDataMemoized)
    )
    setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, props.tab, tableDataMemoized])

  const handleSearchEmailTab = (value: string, tableData: any[]) => {
    return tableData.filter((row) => {
      return (
        row.searchableName.toLowerCase().includes(value.toLowerCase()) ||
        row.searchableTos.some((to: string) =>
          to.includes(value.toLocaleLowerCase())
        )
      )
    })
  }

  const handleSearchCloudTab = (value: string, tableData: any[]) => {
    return tableData.filter((row) =>
      row.searchableName.toLowerCase().includes(value.toLowerCase())
    )
  }

  const handleSort = (_: any, { type }) => {
    setOrderType(type)
  }

  return (
    <>
      <MainContainer id="scan-to-destination-container">
        <TopContentContainer id="top-content-container">
          <CustomSearch
            searchLabel={i18n.assetsProvider.getText("common.search")}
            onChange={handleChange}
            onClear={handleClear}
            onSubmitHandler={handleSubmit}
          />

          <Button
            id="create-new-button"
            leadingIcon={<IconPlus color="colorWhite" filled />}
            onClick={onPlusButtonClick}
            disabled={props.disabled}
          >
            {i18n.assetsProvider.getText("common.createNew")}
          </Button>
        </TopContentContainer>
        <Table
          className={props.disabled ? "disabled" : ""}
          columns={[
            {
              id: "name",
              label: i18n.assetsProvider.getText(
                "pages.ScanDestinations.table.shortcutName"
              ),
            },
            {
              id: "destinations",
              label: i18n.assetsProvider.getText(
                "pages.ScanDestinations.table.destinations"
              ),
              sortable: false,
            },
            {
              id: "filetype",
              label: i18n.assetsProvider.getText(
                "pages.ShortcutCreation.settingsSection.typeTitle"
              ),
              sortable: false,
            },
            { id: "actions", label: "" },
          ]}
          data={pagedData}
          loading={props.isLoading}
          loadingDataLength={3}
          onSort={handleSort}
          preferences={{
            sortBy: {
              id: initialTableSort.current.orderBy,
              type: initialTableSort.current.orderType,
            },
          }}
          i18n={{
            ...tableI18n,
            noItems: i18n.assetsProvider.getText("common.noItemsFound"),
          }}
          pagination={{
            currentPage,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
            pageSize,
            pageSizeOptions: [{ value: 2 }, { value: 4 }, { value: 6 }],
            totalItems: filteredTableData.length,
          }}
        />
      </MainContainer>
      <QuickLookModal
        show={showEmailQuickLookModal || showCloudQuickLookModal}
        title={
          showEmailQuickLookModal
            ? i18n.assetsProvider.getText(
                "pages.ScanDestinations.quickLook.emails",
                {
                  emailsCount: currentShortcut?.smartTask?.smartTaskConfig
                    ?.email
                    ? `(${currentShortcut.smartTask.smartTaskConfig.email.tos.length.toString()})`
                    : "",
                }
              )
            : i18n.assetsProvider.getText(
                "pages.ScanDestinations.quickLook.cloud",
                {
                  destinationsCount: currentShortcut?.smartTask?.smartTaskConfig
                    ?.repositories
                    ? `(${currentShortcut.smartTask.smartTaskConfig.repositories.length.toString()})`
                    : "",
                }
              )
        }
        emailsList={
          showEmailQuickLookModal &&
          currentShortcut?.smartTask?.smartTaskConfig?.email
            ? currentShortcut.smartTask.smartTaskConfig.email.tos
            : []
        }
        tableData={
          showCloudQuickLookModal &&
          currentShortcut?.smartTask?.smartTaskConfig?.repositories
            ? currentShortcut.smartTask.smartTaskConfig.repositories
            : []
        }
        onClose={() => {
          setShowEmailQuickLookModal(false)
          setShowCloudQuickLookModal(false)
        }}
        listOfCapabilitiesIds={listOfCapabilitiesIds}
        cloudId={cloudId}
        listOfInvalidFolderIds={listOfInvalidFolderIds}
        listOfFolderNameItems={listOfFolderNameItems}
        listOfSiteItems={listOfSiteItems}
        shortcutItem={currentShortcut}
      />
      <DialogModal
        show={showDeleteModal}
        titleIcon={<StyledTitleIcon filled size={46} />}
        title={i18n.assetsProvider.getText(
          "pages.ScanDestinations.dialogues.deleteScanDestTitle"
        )}
        upDescription={i18n.assetsProvider.getText(
          "pages.ScanDestinations.dialogues.deleteScanDestDescription"
        )}
        description={i18n.assetsProvider.getText(
          "pages.ScanDestinations.dialogues.removeSmartAccessDescription"
        )}
        isLoading={deletingShortcutLoading}
        buttons={{
          firstLabel: i18n.assetsProvider.getText("common.delete"),
          firstColorScheme: "negative",
          firstFunction: onDeleteButtonClick,
          secondLabel: i18n.assetsProvider.getText("common.cancel"),
          secondFunction: () => {
            setCurrentShortcut(undefined)
            setShowDeleteModal(false)
          },
        }}
        onClose={() => setShowDeleteModal(false)}
      />
      <MaxDestinationModal
        show={showMaxDestinationModal}
        onClose={() => setShowMaxDestinationModal(false)}
      />
    </>
  )
}

export default ScanToDestination
