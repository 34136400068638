import VeneerButton from "@veneer/core/dist/scripts/button"
import styled from "styled-components"

export const Container = styled.div`
  && {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    margin-bottom: 22px;
    > svg {
      margin-right: 10px;
      flex-shrink: 0;
    }
  }
`

export const ItemInformation = styled.div`
  && {
    display: flex;
    flex-direction: row;
    flex-grow: 3;
    gap: 16px;
    margin-right: 20px;
  }
`

export const ItemTitle = styled.div<{ tag: string }>``

export const ItemDescription = styled.div<{ disabled: boolean }>`
  ${(props) => props.disabled && "color: #ADADAD;"}
  && {
    max-width: 428px;
  }
`

export const DestinationsContainer = styled.div`
  > div {
    padding-top: 12px;
    &:not(:last-child) {
      padding-bottom: 16px;
    }
  }
  position: relative;
  z-index: 1;

  &.loader-overlay {
    position: relative;
    z-index: 9999;
  }

  &.loader-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
`

export const ScanToDestinationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: solid #e8e8e8 1px;

  @media (max-width: 362px) {
    flex-wrap: wrap;
  }
`

export const ScanToEmailIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  min-height: 36px;
  background: linear-gradient(135deg, #40defa 0%, #4759f5 100%);
  border-radius: 100px;
`

export const ScanToEmailIconDisabled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  min-height: 36px;
  background: rgba(33, 33, 33, 0.05);
  border-radius: 100px;
`

export const ScanToCloudIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  min-height: 36px;
  background: linear-gradient(141.71deg, #ff63bb 14.17%, #b655f5 85.99%);
  border-radius: 100px;
`
export const ScanToCloudIconDisabled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  min-height: 36px;
  background: rgba(33, 33, 33, 0.05);
  border-radius: 100px;
`

export const ScanToDestinationContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`

export const TitleAndDescriptionContainer = styled.div`
  flex-flow: column wrap;
  margin-right: 24px;
`

export const ScanToDestinationTitle = styled.div<{ disabled: boolean }>`
  ${(props) => props.disabled && "color: #ADADAD;"}
  && {
    align-self: flex-start;
  }
`

export const Button = styled(VeneerButton)`
  && {
    width: 104px;
    height: 36px;
    border-radius: 8px;
    align-self: center;

    @media (max-width: 362px) {
      width: 100%;
      margin-top: 24px;
    }
  }
`
