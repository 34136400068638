import { i18n } from "../../assets/I18n"

export const templateEmptyTosField = (organizationName: string) => {
  return {
    version: "1",
    category: ["email"],
    smartTask: {
      fileType: "pdf",
      jobName: i18n.assetsProvider.getText(
        "pages.ScanDestinations.scanToEmail.titleTemplate"
      ),
      smartTaskConfig: {
        email: {
          tos: [],
          subject: i18n.assetsProvider.getText(
            "pages.ShortcutCreation.emailSection.defaultSubjectText",
            {
              businessName: `[${
                organizationName ??
                i18n.assetsProvider.getText(
                  "pages.ShortcutCreation.emailSection.businessName"
                )
              }]`,
            }
          ),
          message: i18n.assetsProvider.getText(
            "pages.ShortcutCreation.emailSection.defaultBodyText",
            {
              businessName: `[${
                organizationName ??
                i18n.assetsProvider.getText(
                  "pages.ShortcutCreation.emailSection.businessName"
                )
              }]`,
            }
          ),
        },
      },
    },
  }
}
