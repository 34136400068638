import { IconWarningAlt } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const MainContainer = styled.div`
  margin-top: 10px;
  th:nth-child(1) {
    color: ${tokens.colorGray10};
  }
  th:nth-child(2) {
    border-spacing: 10px;
  }
  td {
    width: 25%;
  }
  @media screen and (max-width: ${tokens.smMin}) {
    td:last-child {
      display: none;
    }
    td {
      width: 33.3% !important;
    }
    th:last-child {
      display: none;
    }
  }

  @media screen and (max-height: ${tokens.smMin}) {
    td:last-child {
      display: none;
    }
    td {
      width: 33.3% !important;
    }
    th:last-child {
      display: none;
    }
  }

  .disabled td {
    color: #adadad !important;
  }

  .disabled button {
    color: #adadad !important;
  }

  .disabled svg {
    fill: #adadad !important;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
`

export const StyledTitleIcon = styled(IconWarningAlt)`
  color: ${tokens.colorOrange6} !important;
  margin-top: 2px;
`

export const TopContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px 0px;

  @media screen and (max-width: ${tokens.smMin}) {
    & button {
      display: none;
    }
  }
  @media screen and (max-height: ${tokens.smMin}) {
    & button {
      display: none;
    }
  }
`

export const ScanDestinationsItemsCloudContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const ShortcutActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  float: right;
  svg {
    cursor: pointer !important;
    margin-right: 12px !important;
  }
  svg:nth-child(1) {
    margin-right: 30px !important;
  }
`

export const ShortcutDestinationItemText = styled.p<{ disabled: boolean }>`
  color: ${(props) => (props.disabled ? "#adadad" : "#027aae")};
  margin-left: 4px !important;
  display: flex !important;
  align-items: center !important;
`

export const ShortcutDestinationItemContainer = styled.div`
  margin-right: 13px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: fit-content;
  cursor: pointer;
`

export const ShortcutName = styled.div`
  display: flex;
  svg {
    margin-right: 12px;
  }
`
export const StyledWarningContainer = styled.div`
  button {
    &:hover {
      background-color: transparent !important;
    }
    width: 0 !important;
    height: 0 !important;
    background: transparent;
    border: none;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-right: 5px;
  }
`
