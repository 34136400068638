import { JarvisAuthProvider, JarvisWebHttpClient } from "@jarvis/web-http"
import { Method } from "axios"
import { useCallback, useMemo, useState } from "react"
import {
  CapabilityItem,
  ConnectorToUpdate,
  Site,
} from "../../data/schemas/connector"
import { CreateDeviceSessionResult } from "../../data/schemas/deviceSession"
import {
  FolderNameRequest,
  FolderNameResponse,
} from "../../data/schemas/folders"
import {
  ShortcutItem,
  ShortcutItemDeviceUuid,
} from "../../data/schemas/shortcut"

type AuthUrlRequestData = {
  redirectURI: string
  destinationType: string
  connectorName: string
}

type ShortcutsCount = {
  save: number
  email: number
}

const tenantManagerUrls = {
  0: "https://dev-us1.api.ws-hp.com/shortcuts/tenant-manager",
  1: "https://pie-us1.api.ws-hp.com/shortcuts/tenant-manager",
  2: "https://stage-us1.api.ws-hp.com/shortcuts/tenant-manager",
  3: "https://us1.api.ws-hp.com/shortcuts/tenant-manager",
}

const useTenantApi = (stack: number, authProvider: JarvisAuthProvider) => {
  const [isLoading, setLoading] = useState(false)
  const [deviceShortcuts, setDeviceShortcuts] = useState<ShortcutItem[]>([])
  const [shortcutsCount, setShortcutsCount] = useState({} as ShortcutsCount)
  const [capabilities, setCapabilities] = useState<CapabilityItem[]>([])
  const [
    createDeviceSessionResult,
    setCreateDeviceSessionResult,
  ] = useState<CreateDeviceSessionResult>(null)

  const [folderNameItems, setFolderNameItems] = useState(
    {} as FolderNameResponse
  )

  const [siteItem, setSiteItem] = useState<Site[]>([])

  const [sharepointRequest, setSharepointRequest] = useState(false)

  const [error, setError] = useState({})

  const client: JarvisWebHttpClient = useMemo(
    () =>
      new JarvisWebHttpClient({
        defaultBaseURL: tenantManagerUrls[stack],
        defaultAuthProvider: authProvider,
      }),
    [authProvider, stack]
  )

  const makeRequest = useCallback(
    async (
      method: Method,
      url: string,
      requestData?:
        | ShortcutItem
        | ShortcutItemDeviceUuid
        | AuthUrlRequestData
        | ConnectorToUpdate
        | FolderNameRequest,
      timeout = 30000
    ) => {
      try {
        setLoading(true)
        const { data } = await client.request({
          method: method,
          url: url,
          data: requestData,
          timeout: timeout,
        })
        return data
      } catch (error) {
        setError(error)
        throw error
      } finally {
        setLoading(false)
      }
    },
    [client]
  )

  const getDeviceShortcuts = async (deviceUuid: string) => {
    const shortcuts = await makeRequest(
      "GET",
      `/shortcuts?deviceUuid=${deviceUuid}`
    )
    setShortcutsCount(shortcuts[0]?.categoryCount ?? { email: 0, save: 0 })
    setDeviceShortcuts(shortcuts[0]?.shortcuts ?? [])
  }

  const getShortcuts = async (deviceUuid: string) => {
    const shortcuts = await makeRequest(
      "GET",
      `/shortcuts?deviceUuid=${deviceUuid}`
    )
    return shortcuts[0]?.shortcuts ?? []
  }

  const createDeviceSession = async (deviceUuid: string) => {
    setCreateDeviceSessionResult(
      await makeRequest("POST", `/devices/${deviceUuid}/sessions`)
    )
  }

  const getShortcutById = async (shortcutId: string) => {
    return await makeRequest("GET", `/shortcuts/${shortcutId}`)
  }

  const createShortcut = async (deviceUuid: string, shortcut: ShortcutItem) => {
    return await makeRequest("POST", "/shortcuts", { deviceUuid, shortcut })
  }

  const updateShortcut = async (
    shortcutUuid: string,
    shortcut: ShortcutItem
  ) => {
    return await makeRequest("PUT", `/shortcuts/${shortcutUuid}`, shortcut)
  }

  const deleteShortcut = async (shortcutUuid: string) => {
    return await makeRequest("DELETE", `/shortcuts/${shortcutUuid}`)
  }

  const getCapabilities = useCallback(async () => {
    const connectors = await makeRequest(
      "GET",
      "/connectors/capabilities",
      null,
      60000
    )

    if (connectors?.repositories) {
      setCapabilities(connectors.repositories)
      return connectors.repositories
    }
  }, [makeRequest])

  const getAuthUrl = useCallback(
    (requestData: AuthUrlRequestData) => {
      return makeRequest("POST", "/connectors/authorization", requestData)
    },
    [makeRequest]
  )

  const getConnectorFolders = useCallback(
    (connectorId: string) => {
      return makeRequest("GET", `/connectors/folders/${connectorId}`)
    },
    [makeRequest]
  )

  const getDeviceSupportStatus = useCallback(
    (deviceUuid: string) => {
      return makeRequest("GET", `/devices/${deviceUuid}/sessions/support`)
    },
    [makeRequest]
  )

  const editAccountName = async (
    authId: string,
    newConnector: ConnectorToUpdate
  ) => {
    return await makeRequest(
      "PATCH",
      `/connectors/authorization/${authId}`,
      newConnector
    )
  }

  const getFoldersNames = async (requestData: FolderNameRequest) => {
    const folders = await makeRequest(
      "POST",
      `/connectors/folders/name`,
      requestData
    )
    setFolderNameItems(folders)
  }

  const getAdminSitesSharepoint = async (authId: string) => {
    setSharepointRequest(true)
    const request = await makeRequest("GET", `/connectors/sites/${authId}`)
    setSiteItem(request)
    return request
  }

  return {
    isLoading,
    deviceShortcuts,
    shortcutsCount,
    capabilities,
    createDeviceSessionResult,
    error,
    folderNameItems,
    siteItem,
    sharepointRequest,
    getDeviceShortcuts,
    getShortcutById,
    getShortcuts,
    createShortcut,
    updateShortcut,
    deleteShortcut,
    getCapabilities,
    getAuthUrl,
    getConnectorFolders,
    getDeviceSupportStatus,
    createDeviceSession,
    editAccountName,
    setLoading,
    setCapabilities,
    getFoldersNames,
    getAdminSitesSharepoint,
  }
}

export default useTenantApi
