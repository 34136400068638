export const getFormattedDate = (date) =>
  date.toLocaleDateString(navigator.language, {
    day: "2-digit",
    month: "short",
    year: "numeric",
  })

export const getFormattedTime = (date) =>
  date.toLocaleTimeString(navigator.language)

export const getDateAndTime = (date) =>
  `${getFormattedDate(date)} | ${getFormattedTime(date)}`
