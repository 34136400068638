import { Modal } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const StyledModal = styled(Modal)`
  div:nth-child(${(closeButton: boolean) => (closeButton ? 3 : 2)}) {
    display: grid;

    div {
      justify-self: end;
    }
  }

  div.vn-modal--footer {
    display: flex;
    flex-direction: row-reverse;
  }

  div.vn-modal--dialog {
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ isInfoModal }) =>
      isInfoModal &&
      `
      @media (max-width: ${tokens.smMin}) {
        align-items: flex-start;
      }
        `}
  }

  div.vn-modal--content {
    width: calc(400px + 48px + 48px);

    @media (max-width: ${tokens.smMin}) {
      width: auto;
      max-width: calc(400px + 48px + 48px);
    }

    ${({ isInfoModal }) =>
      isInfoModal &&
      `
        width: 496px;
        height: max-content;
        word-wrap: break-word;
        overflow-wrap: break-word;
        padding: 40px 48px;
        box-sizing: unset !important;

        @media (max-width: ${tokens.smMin}) {
          width: 272px;
        }
      `}
  }

  button.vn-modal__close {
    min-width: 50px;
  }

  button {
    min-width: 123px;
    min-height: 48px;
  }
  z-index: 1400 !important;
`

export const CustomTitle = styled.div`
  margin-top: -5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
export const CustomTitleText = styled.div`
  color: ${tokens.colorGray10};
  margin-left: 5px;
  padding-top: 7px;
`

export const CustomDescription = styled.p`
  color: ${tokens.colorGray10};
  ${({ isInfoModal }) =>
    isInfoModal &&
    `
    font-size: 16px !important;
    overflow-wrap: break-word;
      `}
  padding-top: 24px;
`

export const CustomUpDescription = styled.p`
  ${({ isInfoModal }) =>
    isInfoModal &&
    `font-size: 16px !important;
     padding-bottom: 20px !important;
     padding-top: 19px !important;
     letter-spacing: 0.02em !important;
    `}
  padding-top: 24px;
`

export const NoticeContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  background-color: ${tokens.colorGray6}1a;
  border-radius: 12px;

  @media (max-width: 300px) {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }
`

export const NoticeTitle = styled.div`
  color: ${tokens.colorGray10};
  font-size: 16px;
  padding-bottom: 0px;
`

export const NoticeDescription = styled.div`
  padding-top: 0px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
`

export const NoticeIcon = styled.div`
  margin-top: 14px;
  margin-left: 14px;
`
export const NoticeText = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  padding-left: 14px;
  padding-right: 12px;
  margin-bottom: 14px;
`
