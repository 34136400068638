import { AccountMgtSvcClient } from "@jarvis/web-stratus-client"
import { AES } from "crypto-js"
import { useCallback, useState } from "react"
import { UserEntitlements } from "../../data/schemas/accountMgtApi"
import { entitlementGenerator } from "../../helpers/entitlementGenerator"
import UserMgtSvcV3ClientExt from "../../helpers/user-mgt-svc-v3"

const SCAN_ENTITLEMENTS = {
  smartPro: "ws-hp.com/smart-pro",
  shortcutsBusiness: "ws-hp.com/shortcuts-business",
}

const useAccountMgtApi = (
  accountId: string,
  stack: number,
  authProvider: any
) => {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState({})

  const fetchUserInfo = useCallback(async () => {
    const userMgtSvcClient = new UserMgtSvcV3ClientExt(stack, authProvider)
    const response = await userMgtSvcClient.getCurrentActiveUser()
    return response.data
  }, [authProvider, stack])

  const getEntitlementStatus = useCallback(async () => {
    try {
      setLoading(true)
      const userInfo = await fetchUserInfo()
      const userId: string = userInfo.resourceId

      const client = new AccountMgtSvcClient(stack, authProvider)
      const { data } = await client.getProgramInfos(accountId, userId)
      const levelType = Object.keys(data.programInfoMap)
      const userEntitlements = data.programInfoMap[
        levelType[0]
      ] as UserEntitlements

      userEntitlements.entitlementList.some(
        (entitlement) =>
          entitlement.serviceId === SCAN_ENTITLEMENTS.smartPro &&
          entitlement.softwareProductInfoList?.some(
            (softwareProduct) =>
              softwareProduct.serviceId === SCAN_ENTITLEMENTS.shortcutsBusiness
          )
      )
        ? sessionStorage.setItem(
            "entitlement",
            AES.encrypt("true", entitlementGenerator.entitlement)
          )
        : sessionStorage.setItem(
            "entitlement",
            AES.encrypt("false", entitlementGenerator.entitlement)
          )
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [accountId, authProvider, fetchUserInfo, stack])

  return {
    isLoading,
    error,
    getEntitlementStatus,
  }
}

export default useAccountMgtApi
