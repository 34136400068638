import { Card, TextBox } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const NameTitle = styled.h6`
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 23px;
  @media (max-width: ${tokens.lgMin}) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  @media (max-width: ${tokens.smMin}) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`

export const NameInput = styled(TextBox)`
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  padding: 23px;
  border-top: 1px solid ${tokens.colorGray2};

  #shortcut-name-input-label {
    @media (max-width: ${tokens.smMin}) {
      width: 80%;
      max-width: 80%;
    }

    @media (max-width: 360px) {
      width: 70%;
    }
  }
`

export const MainContainer = styled(Card)`
  display: grid;
  grid-template-rows: auto auto;
`
