import { Button, ButtonGroup, IconMinusCircle } from "@veneer/core"
import React from "react"
import { i18n } from "../../assets/I18n"
import {
  CustomDescription,
  CustomTitle,
  CustomTitleText,
  StyledModal,
} from "./styles"

type MaxDestinationModalProps = {
  show: boolean
  onClose: { (): void }
}

const MaxDestinationModal = ({ show, onClose }: MaxDestinationModalProps) => {
  return (
    <StyledModal
      id="max-destination-modal-container"
      onClose={onClose}
      show={show}
      footer={
        <ButtonGroup>
          <Button
            id="max-destination-close-button"
            appearance="primary"
            onClick={onClose}
          >
            {i18n.assetsProvider.getText("common.close")}
          </Button>
        </ButtonGroup>
      }
    >
      <CustomTitle id="max-destination-modal-title-container">
        <IconMinusCircle focusable size={50} filled color="colorRed6" />
        <CustomTitleText className="title-small">
          {i18n.assetsProvider.getText(
            "pages.ScanDestinations.toasts.createNewError"
          )}
        </CustomTitleText>
      </CustomTitle>
      <CustomDescription className="body-large">
        {i18n.assetsProvider.getText(
          "pages.ScanDestinations.toasts.maxScanDestinations"
        )}
      </CustomDescription>
    </StyledModal>
  )
}

export default MaxDestinationModal
