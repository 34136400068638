import { ToastProvider } from "@veneer/core"
import { createBrowserHistory } from "history"
import React from "react"
import { Route, Router, Switch } from "react-router-dom"
import { DeviceProps } from "../mfe/types/device"
import { CdmEventOptions, ShellProps } from "../mfe/types/shell"
import { i18n } from "./assets/I18n"
import SolutionItem from "./components/SolutionItem"
import { createSendEvent } from "./helpers/dataCollection"
import AuthorizationRedirect from "./pages/AuthorizationRedirect"
import ScanDestinationsTabs from "./pages/ScanDestinationsTabs"
import ShortcutCreationForm from "./pages/ShortcutCreationForm"
import CustomLDProvider from "./providers/customLDProvider"
import { GlobalStyle } from "./styles/global"

type AppProps = {
  stack: number
  shell: ShellProps
  printer: DeviceProps
  onFinish?: ({ isSupported }: { isSupported: boolean }) => void
}

export const history = createBrowserHistory()

const App = ({ stack, shell, printer, onFinish }: AppProps) => {
  const { authProvider, navigation, localization, analytics, orgSelector } =
    shell?.v1
  i18n.setSupportedLocale(localization?.locale ?? "en-US")

  const cdmEventOptions: CdmEventOptions = {
    activity: "ScanDestinations-v01",
    options: {
      metadata: {
        associatedDeviceUuid: printer?.identity?.deviceUuid,
        associatedDeviceProductNumber: printer?.identity?.makeAndModel?.number,
      },
    },
  }

  const sendUiEvent = createSendEvent(analytics, cdmEventOptions)

  const container = document.body

  // Even though the first defined route is the same as Printers (/printer/:cloudId),
  // it's not an override! So when you history.push to this route it won't render just
  // that component, it will first render the Printer's page and then, inside of it
  // (Basic Setting), the solution component will be displayed

  return (
    <ToastProvider>
      <GlobalStyle />
      <CustomLDProvider stack={stack}>
        <Router history={navigation || history}>
          <Switch>
            <Route exact path=":base(/printers)/:cloudId">
              <SolutionItem
                stack={stack}
                authProvider={authProvider}
                orgSelector={orgSelector}
                printer={printer}
                onFinish={onFinish}
                sendUiEvent={sendUiEvent}
                organizationName={orgSelector?.getCurrentTenant()?.accountName}
              />
            </Route>
            <Route exact path=":base(/printers)/:cloudId/scan-destinations">
              <ScanDestinationsTabs
                stack={stack}
                authProvider={authProvider}
                printer={printer}
                sendUiEvent={sendUiEvent}
              />
            </Route>
            <Route
              path={[
                ":base(/printers)/:cloudId/scan-destinations/email/add",
                ":base(/printers)/:cloudId/scan-destinations/cloud/add",
              ]}
            >
              <ShortcutCreationForm
                stack={stack}
                authProvider={authProvider}
                printer={printer}
                container={container}
                sendUiEvent={sendUiEvent}
                organizationName={orgSelector?.getCurrentTenant()?.accountName}
              />
            </Route>
            <Route
              path={[
                ":base(/printers)/:cloudId/scan-destinations/:shortcutId/email/edit",
                ":base(/printers)/:cloudId/scan-destinations/:shortcutId/cloud/edit",
              ]}
            >
              <ShortcutCreationForm
                stack={stack}
                authProvider={authProvider}
                printer={printer}
                container={container}
                sendUiEvent={sendUiEvent}
                organizationName={orgSelector?.getCurrentTenant()?.accountName}
              />
            </Route>
            <Route path=":base(/scan-destinations)/connectors/callback">
              <AuthorizationRedirect
                stack={stack}
                authProvider={authProvider}
              />
            </Route>
          </Switch>
        </Router>
      </CustomLDProvider>
    </ToastProvider>
  )
}

export default App
