import { Modal } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const StyledModal = styled(Modal)<{ wide: boolean }>`
  z-index: 1400 !important;
  div.vn-modal--content {
    max-height: 480px;
  }

  div.vn-modal--body {
    overflow-y: hidden;
    padding: 2px;
  }

  div.vn-modal--footer {
    display: grid;
    div {
      justify-self: end;
    }
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const TitleText = styled.div`
  color: ${tokens.colorGray10};
  padding-bottom: 24px;
`
