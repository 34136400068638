import { ProgressIndicator } from "@veneer/core"
import React from "react"
import { LoaderContainer } from "./styles"

type LoaderProps = {
  isQuickSetModalEnabled?: boolean
}
const Loader = (props: LoaderProps) => (
  <LoaderContainer
    id="loader-container"
    data-testid="loader-container"
    isQuickSetModalEnabled={props.isQuickSetModalEnabled}
  >
    <ProgressIndicator />
  </LoaderContainer>
)

export default Loader
