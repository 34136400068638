import React from "react"
import { DeviceProps } from "./types/device"
import { ShellProps } from "./types/shell"
import App from "../src/App"

declare global {
  interface Window {
    Shell: ShellProps
  }
}

type AppProps = {
  name: string
  stack: number
  printer: DeviceProps
  onFinish?: { ({ isSupported: boolean }): void }
}

if (
  process.env.NODE_ENV === "development" &&
  (process.env.REACT_APP_MOCK_CONNECTORS_AUTHORIZATION === "true" ||
    process.env.REACT_APP_MOCK_CONNECTORS_CAPABILITIES === "true" ||
    process.env.REACT_APP_MOCK_CONNECTORS_FOLDERS === "true" ||
    process.env.REACT_APP_MOCK_DEVICE_CACHE_API === "true" ||
    process.env.REACT_APP_MOCK_DEVICE_API === "true" ||
    process.env.REACT_APP_MOCK_SCAN_DESTINATIONS === "true" ||
    process.env.REACT_APP_MOCK_SHORTCUTS === "true")
) {
  const { worker } = require("../src/mocks/browser")
  worker.start()
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
const Root = (props: AppProps) => {
  const shell = window.Shell as ShellProps

  return <App {...props} shell={shell} />
}

export default Root
