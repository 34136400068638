import { Card } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const ScanDestinationsContainer = styled.div`
  max-width: 100%;
`

export const StyledCard = styled(Card)`
  padding: 10px 20px 20px;
`
export const ScanDestinationsTabsContainer = styled.div`
  margin-top: 30px;
  padding: 0 30px;
`
export const ErrorWidgetContainer = styled.div`
  padding: 20% 0;
`

export const LastUpdatedContainer = styled.div`
  align-items: center;
  color: ${tokens.colorGray10};
  display: flex;
  padding-left: 24px;
  margin-bottom: -12px;
  line-height: 0px;

  svg {
    margin-right: 5px;
  }
`
