import tokens from "@veneer/tokens"
import styled from "styled-components"

export const ShortcutDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  max-width: 430px;
  flex: 1 1 50%;

  @media screen and (max-width: ${tokens.mdMin}) {
    align-items: start;
  }
`

export const ScanToEmailContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
`

export const EmailIcon = styled.div`
  width: 36px;
  height: 36px;
  background: linear-gradient(135deg, #40defa 13.89%, #4759f5 86.11%);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
    height: 18.08px;
    padding-bottom: 2px;
  }
`

export const EmailText = styled.h6`
  font-family: "Forma DJR Micro";
  font-size: 16px;
  padding-top: 3px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #212121;
`

export const EmailCounter = styled.div<{
  emailCount: string
}>`
  width: 22px;
  height: 16px;
  background: ${(props) =>
    props.emailCount === "0" ? "#2121210D" : "#027AAE"};
  border-radius: 24px;
  padding: 4px 4px 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const EmailCountNumber = styled.div<{
  emailCount: string
}>`
  font-family: "Forma DJR UI";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  padding-right: 4px;
  padding-top: 2px;
  color: ${(props) => (props.emailCount === "0" ? "#ADADAD" : "#FFFFFF")};
`

export const ScanToCloudContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const CloudIcon = styled.div`
  width: 36px;
  height: 36px;
  background: linear-gradient(135deg, #ff63bb 13.89%, #870dd7 86.11%);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
    height: 13px;
    padding-bottom: 2px;
  }
`

export const CloudText = styled.h6`
  font-family: "Forma DJR Micro";
  font-size: 16px;
  padding-top: 3px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #212121;
`

export const SaveCounter = styled.div<{
  saveCount: string
}>`
  width: 22px;
  height: 16px;
  background: ${(props) => (props.saveCount === "0" ? "#2121210D" : "#027AAE")};
  border-radius: 24px;
  padding: 4px 4px 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SaveCountNumber = styled.div<{
  saveCount: string
}>`
  font-family: "Forma DJR UI";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  padding-right: 4px;
  padding-top: 2px;
  color: ${(props) => (props.saveCount === "0" ? "#ADADAD" : "#FFFFFF")};
`
