import tokens from "@veneer/tokens"
import styled from "styled-components"

export const PrinterDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  max-width: 430px;
  flex: 1 1 50%;

  @media screen and (max-width: ${tokens.mdMin}) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    max-width: 480px;
  }

  @media screen and (min-width: ${"1400px"}) {
    margin-right: 20%;
  }

  @media screen and (min-width: ${"2000px"}) {
    margin-right: 30%;
  }

  @media screen and (max-width: ${tokens.smMin}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
  }
`

export const PrinterImageContainer = styled.div`
  img {
    width: 148px;
    max-height: 148px;
  }

  @media screen and (max-width: ${tokens.smMin}) {
    img {
      width: 106px;
      max-height: 106px;
    }
  }
`

export const PrinterDataContainer = styled.div`
  margin-left: 14px;

  @media screen and (max-width: ${tokens.smMin}) {
    margin-top: 24px;
  }
`

export const Title = styled.div`
  color: ${tokens.colorGray10};
`

export const Subtitle = styled.div`
  color: ${tokens.colorGray10};
  margin-top: 4px;
`

export const ConnectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
`

export const ConnectTitle = styled.div`
  margin-left: 10px;
  color: ${tokens.colorGray10};
`

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  @media screen and (max-width: ${tokens.smMin}) {
    padding: 6px 8px 6px 12px;
  }
`

export const Link = styled.div`
  color: ${tokens.colorHpBlue6} !important;
  cursor: pointer;
  padding: 6px 8px 6px 12px;
`

export const StatusLoader = styled.div`
  padding: 20px 10px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`
