import { Button, IconWarningAlt } from "@veneer/core"
import React from "react"
import { i18n } from "../../assets/I18n"
import { Container, IconContainer, Text } from "./styles"

type ErrorWidgetProps = {
  onRetry: { (params?: any): any }
}

const ErrorWidget = (props: ErrorWidgetProps) => {
  return (
    <Container id="error-widget-container">
      <IconContainer>
        <IconWarningAlt size={36} color="colorGray7" />
      </IconContainer>
      <Text className="label">
        {i18n.assetsProvider.getText("common.unableToLoadData")}
      </Text>
      <Button
        id="error-widget-retry-button"
        appearance="ghost"
        onClick={props.onRetry}
        small={true}
      >
        {i18n.assetsProvider.getText("common.retry")}
      </Button>
    </Container>
  )
}

export default ErrorWidget
