import { IconWarning, Modal, Table } from "@veneer/core"
import tokens from "@veneer/tokens"
import styled from "styled-components"

export const StyledModal = styled(Modal)<{ wide: boolean }>`
  z-index: 1400 !important;
  div.vn-modal--content {
    max-height: 838px;

    @media (min-width: ${tokens.mdMax}) {
      width: ${(props) => (props.wide ? "800px" : "")};
    }
    @media (max-width: ${tokens.mdMax}) and (min-width: ${tokens.smMax}) {
      width: ${(props) => (props.wide ? "600px" : "")};
    }
  }
  div.vn-modal--body {
    overflow-y: hidden;
    padding: 2px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 24px;
`
export const TitleText = styled.div`
  color: ${tokens.colorGray10};
`

export const EmailAddressContainer = styled.div`
  max-height: 702px;
  overflow: auto;
`

export const EmailAddress = styled.p`
  color: ${tokens.colorBlack};
`

export const CloudFormTable = styled(Table)`
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  width: 100% !important;
  min-width: 200px !important;
`

export const ProviderInfoContainer = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
`

export const ProviderInfoTypeEmailContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  padding-left: 11px;
`

export const ProviderEmailText = styled.div`
  color: ${tokens.colorGray10};
`

export const ProviderText = styled.div`
  color: ${tokens.colorGray10};
`

export const ProviderFolderIconContainer = styled.div`
  padding-right: 5px;
  padding-top: 2px;
`
export const ReauthorizeText = styled.div`
  cursor: pointer;
  color: ${tokens.colorHpBlue6} !important;
`

export const ProviderSiteIconContainer = styled.div`
  padding-right: 5px;
  padding-top: 2px;
`

export const InvalidFolder = styled.div`
  width: 35%;
  margin-left: 5px;
  color: #adadad !important;
  cursor: default;

  svg {
    margin-left: 4px;
    margin-bottom: -5px;
  }
`

export const StyledIconWarning = styled(IconWarning)`
  margin-right: 11px;
`
export const AuthenticationFailedContainer = styled.div`
  color: ${tokens.colorGray10};
  margin-top: -15px;
  margin-left: 20px;
`
